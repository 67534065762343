/** @jsxImportSource theme-ui */
import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  createRef,
  useMemo,
} from 'react';

import { Modal } from 'react-bootstrap';
import { isMobileOnly } from 'react-device-detect';
import QuickPinchZoom, {
  make2dTransformValue,
  make3dTransformValue,
  hasTranslate3DSupport,
} from 'react-quick-pinch-zoom';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import CoordinateStrategy from './CoordinateLayout/CoordinateStrategy';
import GridStrategy from './GridLayout/GridStrategy';
import Legend from './Legend';
import ZoomButtons from './ZoomButtons';

import {
  SeatButtonStyle,
  SeatCustomStyle,
  SeatMapSeat,
} from '../../../@types/modelTypes';
import { SEAT_TYPE } from '../../../constants';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import {
  seatSelectionInProgress,
  getSeatGroup,
  isSeatSelected,
  getSeatsSelectedInArea,
  getSeatsAvailableInArea,
  shouldApplySingleSeatRule,
  seatCausingSingleSeatRuleToFire,
  hasCapacityExceededSingleSeatRuleThreshold,
  getSeatNumberFontSize,
  getMaxNumberOfColumns,
  getMinRowHeight,
  getNumberSingleSeatsAvailable,
} from '../../../services/SeatMapHelpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectCartSummary,
  selectConfig,
  selectContentSeats,
  selectIsSeatsFirstJourney,
  selectNumberOfSeatsToSelect,
  selectQueryString,
  selectSeatsModel,
  selectSelectedLanguageCulture,
  selectSelectedSeats,
} from '../../../store/Selectors';
import { ReactComponent as ScreenSvg } from '../../../svgs/seats/screen.svg';
import ActionButton from '../actionbutton/ActionButton';
import RichText from '../richtext/RichText';

const showSeatNumbersAll = 2;
const showSeatNumbersMobile = 1;
const available = 0;
const maxZoomScale = 5;
const minZoomScale = 1;
const zoomInStep = 1.5;
const companionSeatContext = 'companionSeatContext';
const wheelchairSeatContext = 'wheelchairSeatContext';

const defaultSeatStyle: SeatButtonStyle = {
  minHeight: 1,
  fontSize: 1,
  colWidth: 1,
};

interface MapPoint {
  x: number;
  y: number;
}

interface UpdateAction {
  x: number;
  y: number;
  scale: number;
}

interface Props {
  setSingleSeatRule: (singleSeatRuleFired: boolean) => void;
  singleSeatRuleHasFired: boolean;
}

const SeatMapLayout: React.FC<Props> = ({
  setSingleSeatRule,
  singleSeatRuleHasFired,
}) => {
  const dispatch = useDispatch();
  const { isMobileScreenWidth } = useScreenWidth();

  const cartSummary = useSelector(selectCartSummary);
  const config = useSelector(selectConfig);
  const contentSeats = useSelector(selectContentSeats);
  const queryString = useSelector(selectQueryString);
  const seatsModel = useSelector(selectSeatsModel);
  const selectedSeats = useSelector(selectSelectedSeats);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const numberOfSeatsToSelect = useSelector(selectNumberOfSeatsToSelect);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);
  const seatsToAllocate = numberOfSeatsToSelect;
  const numberOfSeatsSelected = selectedSeats.length;

  const [currentSeat, setCurrentSeat] = useState<SeatMapSeat | null>(null);
  const [disabled, setDisabled] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalButtonNo, setModalButtonNo] = useState('');
  const [modalButtonYes, setModalButtonYes] = useState('');
  const [modalTitle, setModalTitle] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [seatButtonStyle, setSeatButtonStyle] = useState(defaultSeatStyle);
  const preselectionInitialised = useRef(false);
  const seatsModalRef = useRef(null);
  const pinchZoomRef = createRef<QuickPinchZoom>();
  const zoomContentRef = useRef<HTMLDivElement | null>(null);
  const [zoom, setZoom] = useState<UpdateAction>({ x: 0, y: 0, scale: 1 });
  const [showMap, setShowMap] = useState(false);
  const [screenImageError, setScreenImageError] = useState(false);

  const numberOfColumns = useMemo(() => {
    return getMaxNumberOfColumns(seatsModel);
  }, [seatsModel]);

  const zoomContentWidth = zoomContentRef.current
    ? zoomContentRef.current.offsetWidth
    : 0;
  const zoomContentHeight = zoomContentRef.current
    ? zoomContentRef.current.offsetHeight
    : 0;

  const forceSeatText: boolean =
    config.seats.showSeatNumbers === showSeatNumbersAll ||
    (isMobileOnly && config.seats.showSeatNumbers === showSeatNumbersMobile);
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const use3DTransform = hasTranslate3DSupport() && !isSafari;
  const makeTransformValue = use3DTransform
    ? make3dTransformValue
    : make2dTransformValue;

  const capacityExceedsSingleSeatRuleThreshold =
    hasCapacityExceededSingleSeatRuleThreshold(
      config.seats.singleSeatRuleThreshold,
      seatsModel
    );

  const onlyOneSeatToSelect = seatsToAllocate - numberOfSeatsSelected <= 1;
  const numberSingleSeatsAvailable = getNumberSingleSeatsAvailable(seatsModel);

  const insufficientSingleSeatsFired =
    onlyOneSeatToSelect && numberSingleSeatsAvailable === 0;

  const overrideSoldAsGroupUsingThreshold =
    config.seats.overrideSoldAsGroupWhenSSRThresholdExceeded &&
    capacityExceedsSingleSeatRuleThreshold;

  const singleSeatRuleFired = shouldApplySingleSeatRule(
    config.seats.applySingleSeatRule,
    capacityExceedsSingleSeatRuleThreshold
  );

  const setPreselectedSeats = useCallback(() => {
    if (!seatsModel || selectedSeats?.length > 0) {
      return;
    }
    seatsModel.seatsLayoutModel.rows.forEach((row) => {
      if (!row.isARow || row.seats.length === 0) return;

      row.seats.forEach((seat) => {
        if (seat.id !== null && seat.isPreSelected) {
          dispatch(actionCreators.addSeat(seat));
        }
      });
    });
  }, [seatsModel, selectedSeats, dispatch]);

  useEffect(() => {
    if (!singleSeatRuleFired && !preselectionInitialised.current) {
      setPreselectedSeats();
      preselectionInitialised.current = true;
    }
    // apply styles
    const seatButtonStyles: SeatButtonStyle = {
      fontSize: getSeatNumberFontSize(numberOfColumns, zoomContentWidth),
      minHeight: getMinRowHeight(numberOfColumns, zoomContentWidth),
      colWidth: Math.floor(zoomContentWidth / numberOfColumns),
    };
    setSeatButtonStyle(seatButtonStyles);
    if (!showMap) {
      setShowMap(true);
    }
  }, [
    seatsModel,
    zoomContentWidth,
    showMap,
    singleSeatRuleFired,
    setPreselectedSeats,
    numberOfColumns,
  ]);

  useEffect(() => {
    // validate singleSeat rule
    const seatSelectionStillInProgress = seatSelectionInProgress(
      isSeatsFirstJourney,
      selectedSeats,
      seatsToAllocate
    );
    if (singleSeatRuleFired && !seatSelectionStillInProgress) {
      const seatCausingRule = seatCausingSingleSeatRuleToFire(
        selectedSeats,
        seatsModel
      );
      setSingleSeatRule(!!seatCausingRule);
    } else if (singleSeatRuleHasFired) {
      setSingleSeatRule(false);
    }
  }, [
    isSeatsFirstJourney,
    seatsModel,
    seatsToAllocate,
    selectedSeats,
    setSingleSeatRule,
    singleSeatRuleFired,
    singleSeatRuleHasFired,
  ]);

  const onUpdatePinchZoom = ({ x, y, scale }: UpdateAction) => {
    const newZoom = { x, y, scale };
    const { current } = zoomContentRef;
    if (current) {
      const value = makeTransformValue({
        x: newZoom.x,
        y: newZoom.y,
        scale: newZoom.scale,
      });
      current.style.setProperty('transform', value);
    }
    setZoom(newZoom);
  };

  const handleZoomOut = () => {
    const focusPoint: MapPoint = {
      x: zoomContentWidth * 0.5,
      y: zoomContentHeight * 0.5,
    };
    pinchZoomRef.current?.alignCenter({
      x: focusPoint.x,
      y: focusPoint.y,
      scale: 1,
      animated: true,
      duration: 500,
    });
  };

  const handleZoomIn = (scale: number) => {
    if (scale > minZoomScale) {
      const focusPoint: MapPoint = {
        x: zoomContentWidth * 0.5,
        y: zoomContentHeight * 0.5,
      };
      pinchZoomRef.current?.alignCenter({
        x: focusPoint.x,
        y: focusPoint.y,
        scale,
        animated: true,
        duration: 500,
      });
    }
  };

  const onUpdateZoomButtons = (scale: number) => {
    if (scale > minZoomScale) {
      handleZoomIn(scale);
    } else {
      handleZoomOut();
    }
  };

  const handleSeatClick = (seat: SeatMapSeat) => {
    if (disabled) return;
    const shouldUseTimeout = queryString.includes('voiceover=true');

    if (shouldUseTimeout) {
      setDisabled(true);
      selectSeat(seat);
      setTimeout(() => {
        setDisabled(false);
      }, 800);
    } else {
      selectSeat(seat);
    }
  };

  const selectSeat = (seat: SeatMapSeat) => {
    const seatCustomStyle =
      seatsModel.availableSeatTypes.customSeatStyles?.find(
        (x) => x.id == seat.customStyleId
      );
    const isSelected = isSeatSelected(seat, selectedSeats);
    const canSelect =
      (seat.status === available || seat.isPreSelected) &&
      !!getSeatsAvailableInArea(
        seat.areaCategoryCode,
        config,
        seatsModel,
        isSeatsFirstJourney
      );
    setCurrentSeat(seat);
    if (isSelected) {
      removeSelectedSeat(seat);
    } else if (canSelect && seat.type === SEAT_TYPE.WHEELCHAIR) {
      setModal(wheelchairSeatContext);
    } else if (canSelect && seat.type === SEAT_TYPE.COMPANION) {
      setModal(companionSeatContext);
    } else if (
      seatCustomStyle?.data.content[selectedLanguageCulture][
        'customWarningTitle'
      ]
    ) {
      setModal('custom', seatCustomStyle);
    } else if (canSelect) {
      addSelectedSeat(seat);
    }
  };

  const removeSelectedSeat = (seat: SeatMapSeat) => {
    if (
      seat.seatsInGroupCount > 1 &&
      seat.soldAsGroup &&
      !overrideSoldAsGroupUsingThreshold
    ) {
      const seatGroup = getSeatGroup(seat, seatsModel);
      seatGroup.forEach((seatInGroup) => {
        dispatch(actionCreators.removeSeat(seatInGroup));
      });
    } else {
      dispatch(actionCreators.removeSeat(seat));
    }
  };

  const addSelectedSeat = (seat: SeatMapSeat) => {
    const seatAreaCode = seat.areaCategoryCode;
    const seatsAvailableInArea: number = getSeatsAvailableInArea(
      seatAreaCode,
      config,
      seatsModel,
      isSeatsFirstJourney
    );
    const seatsSelected = getSeatsSelectedInArea(seatAreaCode, selectedSeats);
    const numberOfSeatsBeingAdded =
      overrideSoldAsGroupUsingThreshold ||
      insufficientSingleSeatsFired ||
      !seat.soldAsGroup
        ? 1
        : seat.seatsInGroupCount;
    const numberSeatsSelectedIfAddingNewSeat =
      seatsSelected + numberOfSeatsBeingAdded;
    const seatQuotaExceeded =
      seatsAvailableInArea < numberSeatsSelectedIfAddingNewSeat;

    const findAndRemoveIndividualSeats = () => {
      const numberOfSeatsToRemove =
        numberSeatsSelectedIfAddingNewSeat - seatsAvailableInArea;
      const seatsToDeselect: SeatMapSeat[] = selectedSeats.filter(
        (s) => s.areaCategoryCode === seatAreaCode
      );
      for (let index = 0; index < numberOfSeatsToRemove; index++) {
        const s = seatsToDeselect[index];
        removeSelectedSeat(s);
      }
    };
    const findAndRemoveSeatGroup = () => {
      const seatToDeselect: SeatMapSeat | undefined = selectedSeats.find(
        (s) =>
          s.areaCategoryCode === seatAreaCode &&
          s.seatsInGroupCount == seat.seatsInGroupCount
      );
      if (seatToDeselect !== undefined) {
        removeSelectedSeat(seatToDeselect);
      } else {
        findAndRemoveIndividualSeats();
      }
    };

    if (seatQuotaExceeded && selectedSeats.length) {
      if (overrideSoldAsGroupUsingThreshold || insufficientSingleSeatsFired) {
        findAndRemoveIndividualSeats();
      } else {
        findAndRemoveSeatGroup();
      }
    }

    if (numberOfSeatsBeingAdded > 1) {
      const seatGroup = getSeatGroup(seat, seatsModel);
      seatGroup.forEach((seatInGroup) => {
        if (seatInGroup.status === available || seatInGroup.isPreSelected) {
          dispatch(actionCreators.addSeat(seatInGroup));
        }
      });
    } else {
      dispatch(actionCreators.addSeat(seat));
    }
  };

  const setModal = (
    modalContext: string,
    seatCustomStyle?: SeatCustomStyle
  ) => {
    let modalTitle, modalBody;
    const modalButtonNo = contentSeats.seatWarningButtonNo;
    const modalButtonYes = contentSeats.seatWarningButtonYes;

    if (modalContext === companionSeatContext) {
      modalTitle = contentSeats.companionSeatWarningTitle;
      modalBody = contentSeats.companionSeatWarningMessage;
    } else if (modalContext === wheelchairSeatContext) {
      modalTitle = contentSeats.wheelchairSeatWarningTitle;
      modalBody = contentSeats.wheelchairSeatWarningMessage;
    } else {
      modalTitle =
        seatCustomStyle?.data.content[selectedLanguageCulture][
          'customWarningTitle'
        ];
      modalBody =
        seatCustomStyle?.data.content[selectedLanguageCulture][
          'customWarningText'
        ];
    }

    setModalTitle(modalTitle);
    setModalBody(modalBody);
    setModalButtonNo(modalButtonNo);
    setModalButtonYes(modalButtonYes);
    setShowModal(true);
  };

  const handleModalButtons = (modalButtonYes: boolean) => {
    if (modalButtonYes) {
      currentSeat && addSelectedSeat(currentSeat);
    }
    setShowModal(false);
  };

  if (!contentSeats || !showMap) return null;

  const useCoordinateStrategy =
    config.currentCinema.useCoordinateSeatMap &&
    seatsModel.seatsLayoutModel.areas[0].areaDimensions;

  return (
    <Box className='seat-map-container ' sx={{ mt: 6 }}>
      <Box className='seat-map' data-testid='seatmaplayout' sx={{ p: 0 }}>
        <div
          className='cinema-screen-container'
          sx={{
            '.cinema-screen': {
              '.screen-color': {
                fill: 'accent',
              },
            },
            '.label': {
              color: 'mostReadableOnAccentBackground',
            },
          }}
        >
          {config.seats.useScreenImage && !screenImageError ? (
            <div className='cinema-screen cinema-screen-custom'>
              <img
                src={config.seats.customScreenImage || cartSummary.stillUrl}
                className='cinema-screen-image'
                alt={contentSeats.screenLabel}
                onError={(e) => {
                  const target = e.target as HTMLImageElement;
                  target.style.display = 'none';
                  setScreenImageError(true);
                }}
              />
            </div>
          ) : (
            <>
              <ScreenSvg className='cinema-screen' />
              <div className='label  small' sx={{ textAlign: 'center' }}>
                {contentSeats.screenLabel}
              </div>
            </>
          )}
        </div>

        <div
          className='seats-container'
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          {!isMobileScreenWidth && (
            <ZoomButtons
              zoomInHidden={zoom.scale > zoomInStep}
              zoomOutHidden={zoom.scale <= zoomInStep}
              onZoomIn={() => {
                const newScale = zoom.scale + zoomInStep;
                onUpdateZoomButtons(
                  newScale > maxZoomScale ? maxZoomScale : newScale
                );
              }}
              onZoomOut={() => {
                onUpdateZoomButtons(minZoomScale);
              }}
            />
          )}

          <div
            className='zoom-wrapper'
            sx={{
              minHeight: '250px',
              padding: !isMobileScreenWidth
                ? `0 ${config.seats.seatMapXPaddingPx}px`
                : 0,
              mt: 5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <QuickPinchZoom
              onUpdate={onUpdatePinchZoom}
              wheelScaleFactor={500}
              ref={pinchZoomRef}
              shouldInterceptWheel={(e) => !(e.ctrlKey || e.metaKey)}
              maxZoom={maxZoomScale}
              minZoom={0.5}
              draggableUnZoomed={false}
              inertia={false}
              inertiaFriction={0.96}
              containerProps={{
                style: {
                  touchAction: 'auto',
                },
              }}
            >
              <div
                ref={zoomContentRef}
                className='zoom-content'
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {useCoordinateStrategy ? (
                  <CoordinateStrategy
                    forceSeatText={forceSeatText}
                    handleSeatClick={handleSeatClick}
                    seatButtonStyle={seatButtonStyle}
                    zoomContentWidth={zoomContentWidth}
                    overrideSoldAsGroup={
                      overrideSoldAsGroupUsingThreshold ||
                      insufficientSingleSeatsFired
                    }
                  />
                ) : (
                  <GridStrategy
                    forceSeatText={forceSeatText}
                    handleSeatClick={handleSeatClick}
                    seatButtonStyle={seatButtonStyle}
                    overrideSoldAsGroup={
                      overrideSoldAsGroupUsingThreshold ||
                      insufficientSingleSeatsFired
                    }
                    maxCols={numberOfColumns}
                  />
                )}
              </div>
            </QuickPinchZoom>
          </div>
        </div>
        {config && config.seats.showBackOfTheaterInSeatMap && (
          <div
            className='back-of-theater'
            sx={{
              my: 5,
              pt: 2,
              borderTopColor: 'mostReadableOnWebsiteBackground',
              color: 'mostReadableOnWebsiteBackground',
            }}
          >
            <span className='label'>{contentSeats.backOfTheaterLabel}</span>
          </div>
        )}
        <Legend
          showUnavailableWithSelectedTicketsInLegend={
            config.seats.showUnavailableWithSelectedTicketsInLegend
          }
        />
        <Modal
          show={showModal}
          onHide={() => handleModalButtons(false)}
          centered
          className='layout-modal'
          backdrop='static'
          keyboard={false}
          ref={seatsModalRef}
        >
          <Modal.Header>
            {modalTitle && <Modal.Title>{modalTitle}</Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            <RichText text={modalBody} />
          </Modal.Body>
          <Modal.Footer>
            <Box px={3} sx={{ width: '100%' }}>
              {modalButtonNo && (
                <ActionButton
                  variant='secondary'
                  onClick={() => handleModalButtons(false)}
                  mb={1}
                  mt={1}
                >
                  {modalButtonNo}
                </ActionButton>
              )}
              {modalButtonYes && (
                <ActionButton
                  onClick={() => handleModalButtons(true)}
                  mb={1}
                  mt={1}
                  variant='primary'
                >
                  {modalButtonYes}
                </ActionButton>
              )}
            </Box>
          </Modal.Footer>
        </Modal>
      </Box>
    </Box>
  );
};

export default SeatMapLayout;
