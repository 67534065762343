import React, { useState } from 'react';

import { useSelector } from 'react-redux';
import { useLocation, Outlet } from 'react-router-dom';
import { Box, Container, Grid } from 'theme-ui';

import CartSummaryOffcanvas from './CartSummaryOffcanvas';
import ErrorModal from './ErrorModal';
import LayoutHelmet from './LayoutHelmet';
import RemoveConcessionModal from './RemoveConcessionModal';
import ToastNotification from './ToastNotification';

import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useForceRestartJourney } from '../../../hooks/layout/useForceRestartJourney';
import { useHostedPaymentUpdater } from '../../../hooks/layout/useHostedPaymentUpdater';
import { useRemoveNoScroll } from '../../../hooks/layout/useRemoveNoScroll';
import { useStepUpdater } from '../../../hooks/layout/useStepUpdater';
import { useUnloadListeners } from '../../../hooks/layout/useUnloadListeners';
import setContentSecurityPolicy from '../../../scripts/setContentSecurityPolicy';
import { shouldHideCartSummary } from '../../../services/Helpers';
import {
  selectContentSecurityPolicyMetaTagContent,
  selectEnableContentSecurityPolicy,
  selectJourneyTypeConfig,
  selectStep,
} from '../../../store/Selectors';
import CartSummary from '../cartsummary/CartSummary';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import FandBItemModal from '../kiosk/FandBItemModal';
import TotalWrapper from '../totalwrapper/TotalWrapper';

const Layout: React.FC = () => {
  if (window.top && window.location !== window.top.location) {
    window.top.location = window.location;
  }
  const { pathname } = useLocation();
  const { isLargeScreenWidth } = useScreenWidth();

  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const step = useSelector(selectStep);
  const hideCartSummaryForPath = shouldHideCartSummary(pathname);
  const contentSecurityPolicyMetaTagContent = useSelector(
    selectContentSecurityPolicyMetaTagContent
  );
  const enableContentSecurityPolicy = useSelector(
    selectEnableContentSecurityPolicy
  );

  const [showToastNotification, setShowToastNotification] = useState(false);
  const [toastNotificationMessage, setToastNotificationMessage] = useState('');

  const showToast = (message: string) => {
    setShowToastNotification(true);
    setToastNotificationMessage(message);
  };

  const onToastClose = () => {
    setShowToastNotification(false);
    setToastNotificationMessage('');
  };

  const helmetLoaded = setContentSecurityPolicy(
    contentSecurityPolicyMetaTagContent,
    enableContentSecurityPolicy
  );

  useForceRestartJourney();

  useUnloadListeners();

  useRemoveNoScroll();

  useStepUpdater();

  useHostedPaymentUpdater(pathname);

  if (!helmetLoaded) return null;

  const cartSummaryIsShowing = !hideCartSummaryForPath && isLargeScreenWidth;
  const showTotalWrapper = !hideCartSummaryForPath && !isLargeScreenWidth;
  return (
    <>
      <LayoutHelmet />
      <Container
        className='layout'
        variant='layout.fluid'
        data-testid='layout'
        sx={{ position: 'relative', minHeight: '100%' }}
      >
        <Grid
          className='layout-top'
          gap={0}
          columns={!cartSummaryIsShowing ? [1, '1fr'] : [2, '2fr 1fr']}
        >
          <Box className='view' sx={{ pb: [5, 5, 9], px: 3 }}>
            {showTotalWrapper && <TotalWrapper />}
            <Header />
            <Outlet />
          </Box>
          {cartSummaryIsShowing && (
            <CartSummary
              hideTotal={journeyTypeConfig.isSeatsFirst && step < 2}
            />
          )}
        </Grid>
        <Footer />
        <ErrorModal />
        <RemoveConcessionModal />
        <FandBItemModal showToast={showToast} />
        <ToastNotification
          message={toastNotificationMessage}
          show={showToastNotification}
          onToastClose={onToastClose}
        />
        <CartSummaryOffcanvas />
      </Container>
    </>
  );
};

export default Layout;
