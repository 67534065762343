import React, { FC, memo } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  getStartJourneyRouteUsingGlobalState,
  getRouteFromStep,
} from '../../../services/JourneyService';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectJourneyTypeConfig,
  selectQueryString,
  selectDazzlerLocale,
  selectStep,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyButtonWidget'>;
};

export const Button: FC<Props> = ({ widget }) => {
  const selectedLocale = useSelector(selectDazzlerLocale);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const bookingData = useSelector(selectBookingData);
  const step = useSelector(selectStep);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_cookies, _setCookies, removeCookies] = useCookies();
  const queryString = useSelector(selectQueryString);
  const config = useSelector(selectConfig);

  /**
   * Find the localized text for the selected locale.
   */
  const localizedText =
    widget.shape?.text.find((text) => text.locale === selectedLocale)?.value ??
    '';
  const localizedUrl =
    widget.shape?.url.find((url) => url?.locale === selectedLocale)?.value ??
    '';

  const handleAction = () => {
    const path = getRouteFromStep(journeyTypeConfig, step + 1);
    const fullPath = !journeyTypeConfig.isConcessionsOnlyJourney
      ? `/${path}/${bookingData.externalCinemaId}/${bookingData.externalSessionId}`
      : `/${path}`;
    dispatch(actionCreators.setLoading(true));

    switch (widget.shape?.action) {
      case 'SKIP_STEP':
        navigate({
          pathname: fullPath,
          search: location.search,
        });
        break;
      case 'LOGOUT':
        if (bookingData.isUserValidated) {
          removeCookies('requestData', { path: '/' });
        }
        dispatch(actionCreators.clearAvailableConcessions());

        navigate({
          pathname: getStartJourneyRouteUsingGlobalState(
            journeyTypeConfig,
            config,
            bookingData
          ),
          search: queryString,
        });
        break;
      default:
        break;
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleLink = () => {
    window.location.href = localizedUrl;
  };

  const handleClick = () => {
    if (widget.shape?.action) {
      handleAction();
    } else if (widget.shape?.url) {
      handleLink();
    }
  };
  const variant = widget.shape?.variant === 'PRIMARY' ? 'primary' : 'secondary';

  const showIcon = !widget.shape?.hideIcon && widget.shape?.variant !== 'TEXT';

  return (
    <div>
      <ActionButton
        contained
        mx={5}
        onClick={() => handleClick()}
        showIcon={showIcon}
        variant={variant}
      >
        {localizedText}
      </ActionButton>
    </div>
  );
};

export default memo(Button);
