/** @jsxImportSource theme-ui */
import React, {
  useEffect,
  useCallback,
  useState,
  PropsWithChildren,
} from 'react';

import classNames from 'classnames';
import { Button, Collapse } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Flex, Grid } from 'theme-ui';

import { StoredCardPaymentInfo } from './StoredCardPaymentTypes';

import { PaymentProvidersEnum } from '../../../../../@types/enums';
import { PEACH_CODES } from '../../../../../constants';
import { getCardExpiryDateString } from '../../../../../services/Helpers';
import backend from '../../../../../services/RestUtilities';
import {
  selectContent,
  selectToken,
  selectBookingData,
} from '../../../../../store/Selectors';
import CheckBoxButton from '../../../checkboxbutton/CheckBoxButton';
import CollapsiblePaymentProviderContainer from '../cardpayments/CollapsiblePaymentProviderContainer';
import PaymentIconSingle from '../cardpayments/paymenticons/PaymentIconSingle';

interface Props {
  setSelectedStoredCardTokenIdentifier: (
    selectedStoredCardTokenIdentifier: string | null
  ) => void;
  shouldStoreCardToken: boolean;
  setShouldStoreCardToken: (shouldStoreCardToken: boolean) => void;
  paymentFormHasValidationError?: boolean;
  paymentProvider: PaymentProvidersEnum;
  handleNewPaymentFormShow?: () => void;
  handleShouldStoreCardTokenChanged?: (shouldStoreCardToken: boolean) => void;
  shouldStoreCardTokenCheckboxDisabled?: boolean;
}

const StoredCardPayment: React.FC<PropsWithChildren<Props>> = ({
  setSelectedStoredCardTokenIdentifier,
  children,
  shouldStoreCardToken,
  setShouldStoreCardToken,
  paymentFormHasValidationError,
  paymentProvider,
  handleNewPaymentFormShow,
  handleShouldStoreCardTokenChanged,
  shouldStoreCardTokenCheckboxDisabled,
}) => {
  const dataToken = useSelector(selectToken);
  const bookingData = useSelector(selectBookingData);
  const content = useSelector(selectContent);

  const [showNewCardPayment, setShowNewCardPayment] = useState<boolean>(false);

  const [storedCards, setStoredCards] = useState<
    StoredCardPaymentInfo[] | undefined
  >(undefined);
  const [selectedCard, setSelectedCard] = useState<
    StoredCardPaymentInfo | undefined
  >(undefined);

  const [showRemoveCardExpando, setShowRemoveCardExpando] =
    useState<boolean>(false);

  const getStoredCardInfo = useCallback(async () => {
    const data = {
      paymentProvider,
      dataToken: dataToken,
    };
    const response = await backend.post(
      'api/Payment/GetMemberInfoPaymentsForPaymentProvider',
      data
    );
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      const listInfoPayment: StoredCardPaymentInfo[] =
        response.content.listInfoPayment;

      if (listInfoPayment.length) {
        setShowNewCardPayment(false);
      }
      setStoredCards(
        listInfoPayment.map((storedCardPaymentInfo) => {
          const result: StoredCardPaymentInfo = {
            cardNumber: storedCardPaymentInfo.cardNumber,
            idPayment: storedCardPaymentInfo.idPayment,
            tokenIdentifier: storedCardPaymentInfo.tokenIdentifier,
            paymentMethod: storedCardPaymentInfo.paymentMethod,
            expirationCard: storedCardPaymentInfo.expirationCard,
            expirationToken: storedCardPaymentInfo.expirationToken,
          };
          return result;
        })
      );
    } else {
      setStoredCards([]);
    }
  }, [dataToken, paymentProvider]);

  const deleteStoredCardById = useCallback(
    async (storedCardTokenIdentifier: string) => {
      const data = {
        paymentProvider: paymentProvider,
        dataToken: dataToken,
        tokenIdentifier: storedCardTokenIdentifier,
      };
      await backend.post('api/Payment/DeleteMemberInfoPayment', data);
    },
    [dataToken, paymentProvider]
  );

  const setSelectedStoredCardAndStoredCardTokenIdentifier = (
    selectedStoredCard: StoredCardPaymentInfo | undefined
  ) => {
    setSelectedCard(selectedStoredCard);
    setSelectedStoredCardTokenIdentifier(
      selectedStoredCard?.tokenIdentifier ?? null
    );
  };

  const displayCardLastFour = (
    storedCardPaymentInfo: StoredCardPaymentInfo
  ) => {
    const cardLastFour = (cardNumber: string) => {
      return cardNumber.substring(cardNumber.length - 4);
    };
    return (
      <div className='tiny'>
        <span sx={{ ml: 1, display: ['none', 'inline'], fontWeight: 'bold' }}>
          &bull;&bull;&bull;&bull;
        </span>
        <span className='spaced' sx={{ mx: 1 }}>
          {cardLastFour(storedCardPaymentInfo.cardNumber)}
        </span>
      </div>
    );
  };

  const displayCardExpiry = (storedCardPaymentInfo: StoredCardPaymentInfo) => {
    const cardExpDateTime = (cardExpDateString: string) => {
      const date = new Date(cardExpDateString);
      return getCardExpiryDateString(date);
    };
    return (
      <div className='tiny ml'>
        <span>{content.payment.storedCardPaymentExpirationLabel}: </span>
        <span className='spaced'>
          {cardExpDateTime(storedCardPaymentInfo.expirationCard)}
        </span>
      </div>
    );
  };

  const toggleShowPayWithNewCard = (show: boolean) => {
    if (show) {
      handleNewPaymentFormShow && handleNewPaymentFormShow();
    }

    setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    setShowRemoveCardExpando(false);
    setShowNewCardPayment(show);
  };

  const toggleSelectedCard = (selectedStoredCard: StoredCardPaymentInfo) => {
    if (selectedCard === selectedStoredCard) {
      setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    } else {
      setShowNewCardPayment(false);
      setSelectedStoredCardAndStoredCardTokenIdentifier(selectedStoredCard);
    }
    setShowRemoveCardExpando(false);
  };

  const handleRemoveCardConfirmClicked = (
    storedCardToDelete: StoredCardPaymentInfo
  ) => {
    const remainingStoredCards =
      storedCards?.filter(
        (x) => x.tokenIdentifier !== storedCardToDelete.tokenIdentifier
      ) ?? [];

    if (selectedCard?.tokenIdentifier === storedCardToDelete.tokenIdentifier) {
      setSelectedStoredCardAndStoredCardTokenIdentifier(undefined);
    }

    setStoredCards(remainingStoredCards);
    deleteStoredCardById(storedCardToDelete.tokenIdentifier);
  };

  // Loads stored cards for member
  useEffect(() => {
    if (bookingData && bookingData.isUserValidated && !!paymentProvider) {
      getStoredCardInfo();
    }
  }, [bookingData, getStoredCardInfo, paymentProvider]);

  // on validation error, show new card form
  useEffect(() => {
    if (paymentFormHasValidationError && !selectedCard) {
      setShowNewCardPayment(true);
    }
  }, [paymentFormHasValidationError, selectedCard]);

  return (
    <div
      className={classNames(
        'stored-card-payment',
        PaymentProvidersEnum[paymentProvider]
      )}
    >
      <div className='stored-card-payment-item-rows' sx={{ p: 0 }}>
        <div sx={{ p: 0 }}>
          {storedCards && storedCards.length > 0
            ? content.payment.storedCardPaymentSelectSavedCardText
            : content.payment.storedCardPaymentNoSavedCardsText}
        </div>
        {storedCards &&
          storedCards.map((storedCard) => {
            const isChecked = storedCard === selectedCard;
            return (
              <div
                key={storedCard.idPayment}
                className={classNames(
                  'stored-card-payment-item-row',
                  isChecked && 'glow'
                )}
                sx={{ p: 2, mt: 2 }}
                onClick={() => toggleSelectedCard(storedCard)}
              >
                <Flex
                  sx={{ justifyContent: 'space-between', alignItems: 'center' }}
                >
                  <Flex
                    className='card-details'
                    sx={{
                      alignItems: 'center',
                    }}
                  >
                    <PaymentIconSingle
                      creditCardType={storedCard.paymentMethod.toLowerCase()}
                      isActive={true}
                    />
                    {displayCardLastFour(storedCard)}
                    {displayCardExpiry(storedCard)}
                  </Flex>

                  <Flex sx={{ alignItems: 'center' }}>
                    {isChecked && (
                      <Button
                        onClick={(e) => {
                          e.stopPropagation();
                          setShowRemoveCardExpando(!showRemoveCardExpando);
                        }}
                        variant='link'
                        className='remove-button tiny'
                        sx={{
                          textTransform: 'uppercase',
                          color: 'uiError',
                          '&:hover': {
                            color: 'uiError',
                          },
                        }}
                      >
                        <span>
                          -{' '}
                          {
                            content.payment
                              .storedCardPaymentRemoveCardButtonText
                          }{' '}
                          -
                        </span>
                      </Button>
                    )}
                  </Flex>
                  <CheckBoxButton checked={isChecked} smaller />
                </Flex>
                {isChecked && showRemoveCardExpando && (
                  <Flex sx={{ mt: 2 }}>
                    <Collapse
                      in={showRemoveCardExpando}
                      className='bordered contained'
                    >
                      <div>
                        <p sx={{ mt: 0 }}>
                          {
                            content.payment
                              .storedCardPaymentRemoveCardConfirmationText
                          }
                        </p>

                        <Grid sx={{ mt: 4 }} columns={[1, 2]}>
                          <Button
                            className='contained'
                            variant='danger'
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRemoveCardExpando(false);
                              handleRemoveCardConfirmClicked(storedCard);
                            }}
                            sx={{ justifyContent: 'center' }}
                          >
                            {
                              content.payment
                                .storedCardPaymentRemoveCardConfirmationYesButtonText
                            }
                          </Button>
                          <Button
                            className='contained'
                            variant='secondary'
                            sx={{ justifyContent: 'center' }}
                            onClick={(e) => {
                              e.stopPropagation();
                              setShowRemoveCardExpando(false);
                            }}
                          >
                            {
                              content.payment
                                .storedCardPaymentRemoveCardConfirmationNoButtonText
                            }
                          </Button>
                        </Grid>
                      </div>
                    </Collapse>
                  </Flex>
                )}
              </div>
            );
          })}
      </div>
      <CollapsiblePaymentProviderContainer
        show={showNewCardPayment}
        setShow={() => toggleShowPayWithNewCard(!showNewCardPayment)}
        headingContent={
          <div
            className='h3'
            sx={{ textAlign: 'left', textTransform: 'uppercase' }}
          >
            {content.payment.storedCardPaymentPayWithNewCardHeading}
          </div>
        }
        styles={{ mt: 2 }}
        hasOnePaymentOption={false}
        removePaymentPadding={true}
        paymentProvider={paymentProvider}
        isShownClassName='glow'
      >
        {children}

        <Flex sx={{ py: 2, px: 4, alignItems: 'center' }}>
          <CheckBoxButton
            checked={shouldStoreCardToken}
            disabled={shouldStoreCardTokenCheckboxDisabled}
            onClick={() => {
              const newValue = !shouldStoreCardToken;
              setShouldStoreCardToken(newValue);
              handleShouldStoreCardTokenChanged &&
                handleShouldStoreCardTokenChanged(newValue);
            }}
            smaller
          />
          <div>
            <label htmlFor='stored-card-save-new-card'>
              {content.payment.storedCardPaymentSaveNewCardText}
            </label>
          </div>
        </Flex>
      </CollapsiblePaymentProviderContainer>
    </div>
  );
};

export default StoredCardPayment;
