/** @jsxImportSource theme-ui */
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Modal } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { DeliveryPreference } from '../../../@types/enums';
import {
  BookingFeeStrategy,
  Concession,
  GetSeatsRequestModel,
  GlobalState,
  SelectSeatsModel,
  Tickets,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useScreenWidth } from '../../../contextProviders/screenWidthContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useDynamicSeating } from '../../../hooks/useDynamicSeating';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getTotalNumberOfSeatsWithinSelectedTickets } from '../../../services/Helpers';
import { getStepNumberForDisplay } from '../../../services/JourneyService';
import { getContentForError } from '../../../services/PeachErrorResolver';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectAppliedDeals,
  selectBookingData,
  selectConfig,
  selectContent,
  selectCountdownEndTime,
  selectDeals,
  selectNumberOfSeatsToSelect,
  selectSeatsModel,
  selectSelectedSeats,
  selectStep,
  selectTicketTypes,
  selectToken,
  selectContinueButtonText,
  selectDynamicSeatingEnabled,
  selectIsDeliveryOptionBasedOnSeatingArea,
  selectSelectedFaBConcessions,
  selectHasSeatsWithSeatDeliveryAvailable,
  selectIsSeatsFirstJourney,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import SeatMapLayout from '../../common/seats/SeatMapLayout';
import SeatsContainer from '../../common/seats/SeatsContainer';
import SelectedHeading from '../../common/selectedHeading/SelectedHeading';
import ShowtimeHero from '../../common/showtimehero/ShowtimeHero';
import DealsIntroduction from '../../common/tickets/DealsIntroduction';
import TotalWrapper from '../../common/totalwrapper/TotalWrapper';
import DazzlerWrapper from '../../dazzlercommon/DazzlerWrapper';
import LegacyWrapper from '../../dazzlercommon/LegacyWrapper';

const Seats: React.FC = () => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const { isLargeScreenWidth } = useScreenWidth();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const seatsModel = useSelector(selectSeatsModel);
  const selectedSeats = useSelector(selectSelectedSeats);
  const step = useSelector(selectStep);
  const ticketTypes = useSelector(selectTicketTypes);
  const token = useSelector(selectToken);
  const appliedDeals = useSelector(selectAppliedDeals);
  const dealsInTicketsStep = useSelector(
    (state: GlobalState) => state.dealsInTicketsStep
  );
  const dynamicSeatingEnabled = useSelector(selectDynamicSeatingEnabled);

  const [singleSeatRuleFired, setSingleSeatRuleFired] = useState(false);
  const countdownEndTime = useSelector(selectCountdownEndTime);
  const deals = useSelector(selectDeals);
  const numberOfSeatsToSelect = useSelector(selectNumberOfSeatsToSelect);
  const continueButtonText = useSelector(selectContinueButtonText);
  const hasSeatsWithSeatDeliveryAvailable = useSelector(
    selectHasSeatsWithSeatDeliveryAvailable
  );
  const isDeliveryOptionBasedOnSeatingArea = useSelector(
    selectIsDeliveryOptionBasedOnSeatingArea
  );
  const selectedFaBConcessions = useSelector(selectSelectedFaBConcessions);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);
  const [showModal, setShowModal] = useState(false);
  const concessionUpdateModalRef = useRef(null);

  useValidateJourney();

  useAnalyticsTrackOnce(TrackingEvent.SEATS_LANDING);

  useDynamicSeating();

  const hasDeals = !!deals && deals.length;

  const hasConcessionsToBePickedUp: boolean = selectedFaBConcessions.list.some(
    (c) => c.isAvailableForPickupAtCounter
  );
  const hasConcessionsToBeRemoved: boolean = selectedFaBConcessions.list.some(
    (c) => !c.isAvailableForPickupAtCounter
  );

  const shouldValidateConcessions =
    !hasSeatsWithSeatDeliveryAvailable &&
    isDeliveryOptionBasedOnSeatingArea &&
    selectedFaBConcessions.list.length > 0;

  const getSeatsModel = useCallback(async () => {
    if (!bookingData) return;
    dispatch(actionCreators.setLoading(true));
    const data: GetSeatsRequestModel = {
      cinemaId: bookingData.cinemaId,
      sessionId: bookingData.sessionId,
      dataToken: token,
    };
    const response = await backend.post('api/Seats/GetSeats', data);
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      dispatch(
        actionCreators.setSeatsModel(response.content as SelectSeatsModel)
      );
    } else {
      dispatch(
        actionCreators.setError(
          content.error.seatMapErrorRichText,
          response.content.peachCode
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  }, [bookingData, content, dispatch, token]);

  useEffect(() => {
    if (seatsModel) {
      if (seatsModel.peachCode == PEACH_CODES.sessionSoldOut) {
        dispatch(
          actionCreators.setError(
            content.error.sessionSoldOutRichText,
            PEACH_CODES.sessionSoldOut
          )
        );
      } else if (seatsModel.peachCode == PEACH_CODES.sessionNotBookable) {
        dispatch(
          actionCreators.setError(
            content.error.sessionNotBookableRichText,
            PEACH_CODES.sessionNotBookable
          )
        );
      }
    } else if (!seatsModel) {
      getSeatsModel();
    }
  }, [content, dispatch, getSeatsModel, seatsModel]);

  const setSingleSeatRule = (singleSeatRuleFired: boolean) => {
    setSingleSeatRuleFired(singleSeatRuleFired);
  };

  const dispatchTicketsData = (
    tickets: Tickets,
    bookingFeeStrategy: BookingFeeStrategy | null
  ) => {
    if (tickets) {
      dispatch(actionCreators.setAvailableTickets(tickets));
      dispatch(actionCreators.setBookingFeeAndTax(0, 0));
      if (appliedDeals) {
        dispatch(actionCreators.setAppliedDealsWithDiscount([], 0));
      }
      if (dealsInTicketsStep) {
        dispatch(actionCreators.setDealsInTicketsStep([]));
      }

      dispatch(actionCreators.clearCeaCards());
      dispatch(actionCreators.clearMemberCards());
    }
    if (bookingFeeStrategy) {
      dispatch(actionCreators.setBookingFeeStrategy(bookingFeeStrategy));
    }
  };

  const fetchTicketsAndNavigate = async () => {
    dispatch(actionCreators.setLoading(true));
    const data = {
      dataToken: token,
    };
    const response = await backend.post('api/Tickets/GetTickets', data);
    const tickets: Tickets = response.content.selectTicketsModel;
    const hasThirdPartyMemberTickets =
      !!response.content.thirdPartyMembershipNames?.includes(
        config.tickets.thirdPartyMemberTicketsName
      );
    const bookingFeeStrategy: BookingFeeStrategy | null =
      response.content.bookingFeeStrategy;
    dispatchTicketsData(tickets, bookingFeeStrategy);
    dispatch(
      actionCreators.setHasThirdPartyMemberTickets(hasThirdPartyMemberTickets)
    );
    dispatch(
      actionCreators.setThirdPartyMembershipNames(
        response.content.thirdPartyMembershipNames
      )
    );
    dispatch(actionCreators.setLoading(false));
    navigateToNextStep();
  };

  const navigateToNextStep = () => {
    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: true,
    });
  };

  const handleContinueClick = async (skipConcessionValidation?: boolean) => {
    if (shouldValidateConcessions && !skipConcessionValidation) {
      setShowModal(true);
      return;
    }

    if (isDeliveryOptionBasedOnSeatingArea && skipConcessionValidation) {
      hasConcessionsToBeRemoved &&
        dispatch(actionCreators.removeUncollectibleConcessions());

      hasConcessionsToBePickedUp &&
        dispatch(
          actionCreators.setDeliveryPreference(
            DeliveryPreference.COLLECT_FROM_KIOSK
          )
        );
    }

    if (dynamicSeatingEnabled) {
      fetchTicketsAndNavigate();
      return;
    }

    if (!executeRecaptcha) return;

    dispatch(actionCreators.setLoading(true));

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddSeats',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const data = {
      selectedSeats: selectedSeats,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
    };
    const response = await backend.post('api/Seats/', data, turnstileToken);
    if (response.ok) {
      if (response.content.peachCode === PEACH_CODES.noError) {
        const tickets: Tickets = response.content.selectTicketsModel;
        const hasThirdPartyMemberTickets =
          !!response.content.thirdPartyMembershipNames?.includes(
            config.tickets.thirdPartyMemberTicketsName
          );
        const bookingFeeStrategy: BookingFeeStrategy | null =
          response.content.bookingFeeStrategy;
        dispatchTicketsData(tickets, bookingFeeStrategy);
        dispatch(actionCreators.setToken(response.content.dataToken));
        if (config.enableCountDown) {
          dispatch(
            actionCreators.setCountDown(response.content.secondsToExpiration)
          );
        }
        dispatch(actionCreators.setOrderExists());
        dispatch(
          actionCreators.setHasThirdPartyMemberTickets(
            hasThirdPartyMemberTickets
          )
        );
        dispatch(
          actionCreators.setThirdPartyMembershipNames(
            response.content.thirdPartyMembershipNames
          )
        );
        navigateToNextStep();
      } else {
        if (response.content.peachCode === PEACH_CODES.chooseOtherSeats) {
          const seatmapRows = seatsModel.seatsLayoutModel.rows;
          selectedSeats.forEach((selectedSeat) => {
            seatmapRows.every((row) => {
              const foundSeat = row.seats.find(
                (s) => s.seatName === selectedSeat.seatName
              );
              if (foundSeat) {
                foundSeat.isUnavailable = true;
                foundSeat.isSelected = true;
                foundSeat.status = 1;
                return false;
              } else {
                return true;
              }
            });
          });
        } else if (
          response.content.peachCode ===
          PEACH_CODES.seatsUnavailableRefreshLayout
        ) {
          if (response.content.selectSeatsModel) {
            dispatch(
              actionCreators.setSeatsModel(
                response.content.selectSeatsModel as SelectSeatsModel
              )
            );
          }
        }
        dispatch(actionCreators.removeAllSeats());
        dispatch(
          actionCreators.setError(
            getContentForError(response.content.peachCode, content),
            response.content.peachCode
          )
        );
      }
    } else {
      dispatch(actionCreators.setError(content.error.networkErrorRichText));
    }

    if (isSeatsFirstAndTicketsGreaterThanSeats()) {
      dispatch(actionCreators.setTicketTypes(null));
    }

    turnstile?.resetToken();

    dispatch(actionCreators.setLoading(false));
    window.scrollTo(0, 0);
  };

  const isSeatsFirstAndTicketsGreaterThanSeats = () => {
    return (
      ticketTypes &&
      isSeatsFirstJourney &&
      getTotalNumberOfSeatsWithinSelectedTickets(ticketTypes.ticketTypeModels) >
        selectedSeats.length
    );
  };

  if (!content || !config || !seatsModel?.seatsLayoutModel) return null;

  const numberOfSeatsSelected = selectedSeats.length;
  const allSeatsSelected = numberOfSeatsSelected === numberOfSeatsToSelect;
  const buttonDisabled =
    singleSeatRuleFired ||
    (isSeatsFirstJourney &&
      (numberOfSeatsSelected === 0 ||
        numberOfSeatsSelected > numberOfSeatsToSelect)) ||
    (!isSeatsFirstJourney && !allSeatsSelected);

  const handleCancelModal = () => {
    setShowModal(false);
  };

  const handleContinueModal = () => {
    setShowModal(false);
    handleContinueClick(true);
  };

  return (
    <>
      <DazzlerWrapper step='seats' />
      <LegacyWrapper step='seats'>
        <SeatsContainer>
          <ContainedRow textAlign='center'>
            <h1>
              {getStepNumberForDisplay(step)}
              {content.seats.title}
            </h1>
          </ContainedRow>

          <ContainedRow>
            <ShowtimeHero />
          </ContainedRow>
          {isLargeScreenWidth &&
            (!isSeatsFirstJourney || countdownEndTime !== null) && (
              <TotalWrapper />
            )}
          {isSeatsFirstJourney && !!hasDeals && <DealsIntroduction />}

          {content.seats.additionalRichText ? (
            <ContainedRow styles={{ mt: hasDeals ? 4 : 6 }}>
              <RichText text={content.seats.additionalRichText} />
            </ContainedRow>
          ) : null}

          {!isSeatsFirstJourney && allSeatsSelected && (
            <SelectedHeading
              allSelected={allSeatsSelected}
              numberSelected={numberOfSeatsSelected}
              numberToSelect={numberOfSeatsToSelect}
            />
          )}

          <SeatMapLayout
            setSingleSeatRule={setSingleSeatRule}
            singleSeatRuleHasFired={singleSeatRuleFired}
          />

          <ActionButton
            showWarningMessage={singleSeatRuleFired}
            warningMessage={content.seats.singleSeatRuleMessage}
            disabled={buttonDisabled}
            onClick={() => handleContinueClick()}
            sticky={config.seats.useStickyButton}
            showIcon
            contained
            mx={7}
            variant='primary'
            showCartSummaryButtonOnMobile
          >
            {continueButtonText}
          </ActionButton>

          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            centered
            className='error-modal'
            backdrop={true}
            keyboard={true}
            ref={concessionUpdateModalRef}
          >
            <Modal.Header>
              <Modal.Title>{content.seats.cartUpdateModalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>{content.seats.cartUpdateModalExplanationText}</p>
              {hasConcessionsToBeRemoved && (
                <div sx={{ mt: 4 }}>
                  <p sx={{ mb: 4 }}>
                    {content.seats.cartUpdateModalRemovedItemText}
                  </p>
                  <ul>
                    {selectedFaBConcessions?.list
                      .filter(
                        (c: Concession) => !c.isAvailableForPickupAtCounter
                      )
                      .map((c: Concession) => (
                        <li key={c.id}>
                          {c.description}
                          <br />
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {hasConcessionsToBePickedUp && (
                <div sx={{ mt: 4 }}>
                  <p sx={{ mb: 4 }}>
                    {content.seats.cartUpdateModalKioskCollectionText}
                  </p>
                  <ul>
                    {selectedFaBConcessions?.list
                      .filter(
                        (c: Concession) => c.isAvailableForPickupAtCounter
                      )
                      .map((c: Concession) => (
                        <li key={c.id} sx={{ mb: 2 }}>
                          {c.description}
                          <br />
                        </li>
                      ))}
                  </ul>
                </div>
              )}
            </Modal.Body>
            <ContainedRow
              styles={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box>
                <ActionButton
                  variant='secondary'
                  onClick={() => handleCancelModal()}
                  mb={5}
                  mt={5}
                  mx={5}
                >
                  {content.seats.cartUpdateModalCancelButtonTitle}
                </ActionButton>
              </Box>
              <Box>
                <ActionButton
                  variant='primary'
                  onClick={() => handleContinueModal()}
                  mb={5}
                  mt={5}
                  mx={5}
                >
                  {content.seats.cartUpdateModalContinueButtonTitle}
                </ActionButton>
              </Box>
            </ContainedRow>
          </Modal>
        </SeatsContainer>
      </LegacyWrapper>
    </>
  );
};

export default Seats;
