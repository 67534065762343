/** @jsxImportSource theme-ui */
import React, { useEffect, useCallback } from 'react';

import { useCookies } from 'react-cookie';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { ConfirmationRequestModel } from '../../../@types/modelTypes';
import { useConfirmationTracking } from '../../../hooks/useConfirmationTracking';
import { createConfirmation } from '../../../services/Helpers';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfirmationData,
  selectContent,
  selectCurrentCinemaHomeUrl,
  selectCustomer,
  selectGuestSessionToken,
  selectJourneyType,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const LoyaltyUpgradeConfirmation: React.FC = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const { orderId } = useParams();
  const [cookies] = useCookies();
  const content = useSelector(selectContent);
  const confirmationData = useSelector(selectConfirmationData);
  const customer = useSelector(selectCustomer);
  const guestSessionToken = useSelector(selectGuestSessionToken);
  const token = useSelector(selectToken);
  const journeyType = useSelector(selectJourneyType);
  const currentCinemaHomeUrl = useSelector(selectCurrentCinemaHomeUrl);

  useConfirmationTracking('Loyalty');

  const fetchData = useCallback(async () => {
    dispatch(actionCreators.setLoading(true));
    const qs = buildQueryString({
      circuitId: searchParams.get('circuitId') ?? undefined,
      languageCulture: cookies.preferredLanguageCulture,
    });
    const url = `api/Confirmation/${orderId}${qs}`;

    let body: ConfirmationRequestModel | undefined;

    if (guestSessionToken) {
      body = {
        DataToken: token,
        GuestSessionToken: guestSessionToken,
      };
    }

    const response = await backend.post(url, body);
    if (response.ok) {
      const data = response.content;
      const confirmation = createConfirmation(data, customer, journeyType);
      dispatch(actionCreators.setConfirmation(confirmation));
    }

    dispatch(actionCreators.setGuestSessionToken(undefined));
    dispatch(actionCreators.setLoading(false));
  }, [
    dispatch,
    searchParams,
    cookies.preferredLanguageCulture,
    orderId,
    guestSessionToken,
    token,
    customer,
    journeyType,
  ]);

  // fetch data
  useEffect(() => {
    if (!confirmationData || confirmationData.externalOrderId !== orderId) {
      fetchData();
    }
  }, [confirmationData, orderId, fetchData]);

  const email: string = customer.email ?? '';
  if (!content) return null;

  return (
    <div className='payment' data-testid='payment'>
      <ContainedRow>
        <h1 sx={{ textAlign: 'center' }}>
          {content.loyalty.confirmationTitle}
        </h1>
        <h2 sx={{ mt: 5, textAlign: 'center' }}>
          {content.loyalty.confirmationSubHeading}
        </h2>
        <RichText
          text={content.loyalty.upgradeConfirmationRichText.replace(
            '##CUSTOMEREMAIL##',
            email
          )}
          styles={{ mt: 4 }}
        />
        <h2 sx={{ mt: 6, textAlign: 'center' }}>
          {content.loyalty.upgradeConfirmationBenefitsHeading}
        </h2>
        <RichText
          text={content.loyalty.upgradeConfirmationBenefitsRichText}
          styles={{ mt: 4 }}
        />
      </ContainedRow>
      <ActionButton
        contained
        disabled={false}
        mx={5}
        onClick={() => (window.location.href = currentCinemaHomeUrl)}
        showIcon={true}
        sticky={false}
        variant='primary'
      >
        {content.loyalty.confirmationButtonText}
      </ActionButton>
    </div>
  );
};

export default LoyaltyUpgradeConfirmation;
