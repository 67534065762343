/** @jsxImportSource theme-ui */
import React from 'react';

import moment from 'moment';
import { Button } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Box, Flex, Grid } from 'theme-ui';

import ShowtimeDate from './ShowtimeDate';

import { Time } from '../../../@types/modelTypes';
import {
  renderShowtimeAttributeList,
  timeFormat,
} from '../../../services/Helpers';
import {
  selectConfig,
  selectContent,
  selectQueryString,
  selectRequestData,
  selectSelectedLanguageCulture,
  selectShowtimes,
} from '../../../store/Selectors';
import { ReactComponent as SummaryEditSvg } from '../../../svgs/summaryEdit.svg';

interface ShowtimePickerProps {
  showShowtimes: boolean;
  setShowShowtimes: (showShowtimes: boolean) => void;
  displayShowtimePicker: boolean;
}

const ShowtimePicker: React.FC<ShowtimePickerProps> = ({
  showShowtimes,
  setShowShowtimes,
  displayShowtimePicker,
}) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_cookies, setCookies, _removeCookies] = useCookies();
  const { externalCinemaId, externalSessionId } = useParams();

  const config = useSelector(selectConfig);
  const queryString = useSelector(selectQueryString);
  const requestData = useSelector(selectRequestData);
  const showtimes = useSelector(selectShowtimes);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);
  const content = useSelector(selectContent);

  const handleSessionChange = (externalId: string) => {
    const cookieOptions = {
      path: '/',
      expires: moment().add(5, 's').toDate(),
      secure: true,
      sameSite: true,
    };

    setCookies('requestData', requestData, cookieOptions);
    setCookies('isShowtimeChange', true, cookieOptions);

    const url = `/startticketing/${externalCinemaId}/${externalId}${queryString}`;
    window.location.href = url;
  };

  const handleShowSessions = () => {
    setShowShowtimes(true);
  };

  const renderShowtimeButton = (time: Time) => {
    return (
      <Box
        key={`${time.externalSessionId}`}
        className='showtime-button-container'
        sx={{ my: 2, mx: 1 }}
      >
        <Button
          className='showtime-button btn-link'
          disabled={
            moment(time.startDateTime) < moment() ||
            time.externalSessionId === externalSessionId ||
            time.notBookable ||
            time.soldOut
          }
          onClick={() => handleSessionChange(time.externalSessionId)}
          sx={{
            backgroundColor: 'secondaryButtonBackground',
            borderColor: 'accent',
            color: 'secondaryButtonColor',

            '&:disabled': {
              backgroundColor: 'secondaryButtonBackground',
              borderColor: 'accent',
              color: 'secondaryButtonColor',
            },

            '&:hover:enabled, &:focus:enabled, &:focus:hover:enabled': {
              backgroundColor: 'secondaryButtonBackgroundHover',
              borderColor: 'primary',
              color: 'secondaryButtonColorHover',
            },
          }}
        >
          <span className='showtime-hourminute'>
            {moment(time.startDateTime)
              .locale(selectedLanguageCulture)
              .format(timeFormat(selectedLanguageCulture))}
          </span>
        </Button>
      </Box>
    );
  };

  const renderShowtimeRows = () => {
    return (
      showtimes &&
      showtimes.map((showtime) => (
        <Flex
          className='showtime-picker-date-row'
          key={`${showtime.date}`}
          sx={{ textAlign: 'left' }}
        >
          <ShowtimeDate date={showtime.date} />
          <Box
            className='showtime-picker-sessions-container'
            sx={{ width: '100%' }}
          >
            <div
              className='showtime-picker-sessions'
              sx={{
                borderTop: 'bodySeparator',
                pt: 6,
                pb: 4,
              }}
            >
              {showtime.experienceTypes.map((experienceType, index) => (
                <Box key={`experienceType${index}`} sx={{ ml: 4 }}>
                  {experienceType.experienceAttributes.length > 0 && (
                    <Box className='tiny' mb={2}>
                      {renderShowtimeAttributeList(
                        experienceType.experienceAttributes,
                        config.showtimeAttributeIconsInShowtimePickerFormat
                      )}
                    </Box>
                  )}
                  <Grid
                    className='showtime-buttons-container'
                    columns={3}
                    gap={0}
                  >
                    {experienceType.times.map((time) =>
                      renderShowtimeButton(time)
                    )}
                  </Grid>
                </Box>
              ))}
            </div>
          </Box>
        </Flex>
      ))
    );
  };

  return (
    <Box
      className='showtime-picker'
      data-testid='showtime-picker'
      sx={{
        '.update-showtime-btn': {
          color: 'mostReadableOnWebsiteBackground',

          '.icon': {
            '.a': {
              fill: 'primary',
            },
          },

          '&:hover': {
            color: 'primary',
          },
        },
      }}
    >
      {displayShowtimePicker && !showShowtimes && (
        <Flex sx={{ justifyContent: 'center' }}>
          <Button
            className='update-showtime-btn tiny'
            onClick={() => handleShowSessions()}
            variant='link'
            sx={{
              py: 2,
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              '&:hover': {
                py: 2,
              },
            }}
          >
            {content.updateShowtimeText}
            <SummaryEditSvg className='icon' />
          </Button>
        </Flex>
      )}
      {showShowtimes && (
        <Box sx={{ px: 4 }} className='date-rows-container'>
          {renderShowtimeRows()}
        </Box>
      )}
    </Box>
  );
};

export default ShowtimePicker;
