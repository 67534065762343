/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { Offcanvas } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import SeasonPassMovieContainer from './SeasonPassMovieContainer';
import SeasonPassSessionContainer from './SeasonPassSessionContainer';

import {
  GetSeatsRequestModel,
  SeatMapSeat,
  SelectSeatsModel,
} from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectNumberOfSeatsToSelect,
  selectSeatsModel,
  selectSelectedSeasonPass,
  selectSelectedSeats,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import SeatMapLayout from '../seats/SeatMapLayout';
import SeatsContainer from '../seats/SeatsContainer';
import SelectedHeading from '../selectedHeading/SelectedHeading';

interface Props {
  sessionId: number;
}

const SeasonPassSelectSeatContainer: React.FC<Props> = ({ sessionId }) => {
  const dispatch = useDispatch();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [showSeatsCanvas, setShowSeatsCanvas] = useState(false);

  const oldSelectedSeatsState: SeatMapSeat[] = [];
  const [oldSelectedSeats, setOldSelectedSeats] = useState(
    oldSelectedSeatsState
  );
  const [singleSeatRuleFired, setSingleSeatRuleFired] = useState(false);

  const {
    seasonPass,
    continueButtonText,
    error: errorContent,
    seats: seatsContent,
  } = useSelector(selectContent);
  const { seats: seatsConfig } = useSelector(selectConfig);
  const bookingData = useSelector(selectBookingData);
  const token = useSelector(selectToken);
  const seatsModel = useSelector(selectSeatsModel);
  const selectedSeats = useSelector(selectSelectedSeats);
  const selectedSeasonPass = useSelector(selectSelectedSeasonPass);
  const numberOfSeatsToSelect = useSelector(selectNumberOfSeatsToSelect);

  const selectedSession = selectedSeasonPass.sessions.find(
    (s) => s.session.sessionId === sessionId
  );

  const selectedShowtime = selectedSession?.movie.showTimes.find((st) =>
    st.times.some((t) => t.sessionId === selectedSession.session.sessionId)
  );

  if (!selectedSession || !selectedShowtime) {
    return null;
  }

  const numberOfSeatsSelected = selectedSeats?.length ?? 0;
  const allSeatsSelected = numberOfSeatsSelected === numberOfSeatsToSelect;

  const continueButtonDisabled = singleSeatRuleFired || !allSeatsSelected;

  const handleOnClose = async () => {
    const newSelectedSeats = selectedSeats.filter(
      (s) => !oldSelectedSeats.includes(s)
    );

    if (!newSelectedSeats?.length || !executeRecaptcha) {
      setShowSeatsCanvas(false);
      return;
    }

    dispatch(actionCreators.setLoading(true));

    const turnstileToken = await turnstile?.getToken();

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddSeats',
      executeRecaptcha
    );

    const data = {
      selectedSeats: selectedSeats,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
      sessionId: sessionId,
      isSeatsFirst: false,
    };
    const response = await backend.post('api/Seats/', data, turnstileToken);
    if (response.ok) {
      if (response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setSelectedSeats([]));
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setOrderExists());
        const seasonPassSession = selectedSeasonPass.sessions.find(
          (s) => s.session.sessionId === sessionId
        );
        if (seasonPassSession) {
          seasonPassSession.seats = selectedSeats.map((s) => s.seatName);
          dispatch(actionCreators.setSelectedSeasonPass(selectedSeasonPass));
        }
      }
    } else {
      dispatch(actionCreators.setError(errorContent.networkErrorRichText));
    }

    turnstile?.resetToken();

    setShowSeatsCanvas(false);

    dispatch(actionCreators.setLoading(false));
  };

  const handleOnShow = async () => {
    dispatch(actionCreators.setLoading(true));

    dispatch(actionCreators.setSeatsModel(undefined));

    const data: GetSeatsRequestModel = {
      cinemaId: bookingData.cinemaId,
      sessionId,
      dataToken: token,
      isSeatsFirst: false,
    };
    const response = await backend.post('api/Seats/GetSeats', data);
    if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
      const selectSeatsModel = response.content as SelectSeatsModel;
      const selectedSeats =
        selectSeatsModel.seatsLayoutModel.rows
          .flatMap((r) => r.seats)
          .filter((s) => s.isSelected) ?? [];
      dispatch(actionCreators.setSelectedSeats(selectedSeats));
      dispatch(actionCreators.setSeatsModel(selectSeatsModel));
      setOldSelectedSeats(selectedSeats);
    } else {
      dispatch(
        actionCreators.setError(
          errorContent.seatMapErrorRichText,
          response.content.peachCode
        )
      );
    }
    dispatch(actionCreators.setLoading(false));
  };

  const toggleShow = () => setShowSeatsCanvas((s) => !s);

  const setSingleSeatRule = (singleSeatRuleFired: boolean) => {
    setSingleSeatRuleFired(singleSeatRuleFired);
  };

  return (
    <>
      <ActionButton
        variant='secondary'
        onClick={toggleShow}
        mb={5}
        mt={5}
        mx={5}
      >
        {seasonPass.updateSeatsButtonText}
      </ActionButton>

      <Offcanvas
        show={showSeatsCanvas}
        onShow={handleOnShow}
        placement='end'
        backdrop='static'
        sx={{
          backgroundColor: 'websiteBackground',
        }}
      >
        {!!seatsModel && (
          <Offcanvas.Title
            as='div'
            sx={{
              boxShadow: 'mostReadableOnWebsiteBackgroundShadow',
            }}
          >
            <SelectedHeading
              allSelected={allSeatsSelected}
              numberSelected={numberOfSeatsSelected}
              numberToSelect={numberOfSeatsToSelect}
            />
          </Offcanvas.Title>
        )}
        <Offcanvas.Body>
          <Flex sx={{ justifyContent: 'center' }}>
            <Box sx={{ maxWidth: '50%', flexGrow: 1 }}>
              <ContainedRow styles={{ mb: 2, textAlign: 'center' }}>
                <h1>{seatsContent.title}</h1>
              </ContainedRow>

              <div
                sx={{
                  borderTop: 'bodySeparator',
                }}
              >
                <SeasonPassMovieContainer movie={selectedSession.movie}>
                  <SeasonPassSessionContainer showtime={selectedShowtime}>
                    <></>
                  </SeasonPassSessionContainer>
                </SeasonPassMovieContainer>
              </div>
            </Box>
          </Flex>
          {!!seatsModel && (
            <>
              <SelectedHeading
                allSelected={allSeatsSelected}
                numberSelected={numberOfSeatsSelected}
                numberToSelect={numberOfSeatsToSelect}
              />

              <SeatsContainer>
                <SeatMapLayout
                  setSingleSeatRule={setSingleSeatRule}
                  singleSeatRuleHasFired={singleSeatRuleFired}
                />
              </SeatsContainer>
            </>
          )}
        </Offcanvas.Body>
        {!!seatsModel && (
          <Offcanvas.Title as='div'>
            <Flex sx={{ justifyContent: 'center' }}>
              <Box sx={{ maxWidth: '50%', flexGrow: 1 }}>
                <ActionButton
                  variant='primary'
                  onClick={handleOnClose}
                  mx={5}
                  showIcon
                  contained
                  sticky={seatsConfig.useStickyButton}
                  disabled={continueButtonDisabled}
                  showWarningMessage={singleSeatRuleFired}
                  warningMessage={seatsContent.singleSeatRuleMessage}
                >
                  {continueButtonText}
                </ActionButton>
              </Box>
            </Flex>
          </Offcanvas.Title>
        )}
      </Offcanvas>
    </>
  );
};

export default SeasonPassSelectSeatContainer;
