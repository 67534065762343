/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Flex } from 'theme-ui';

import { GoBackButton } from './GoBackButton';
import SeasonPassShowtimesTopBar from './SeasonPassShowtimesTopBar';

import { JOURNEY_TYPES, SEASON_PASS_STEPS } from '../../../constants';
import { selectJourneyType, selectStep } from '../../../store/Selectors';
import ExtraMenu from '../extramenu/ExtraMenu';
import Logo from '../logo/Logo';

const seasonPassBarHeight = 34;
const HeaderContainer: React.FC = () => {
  const step = useSelector(selectStep);
  const journeyType = useSelector(selectJourneyType);

  const shoulShowSeasonPassShowtimesTopBar =
    journeyType === JOURNEY_TYPES.SEASON_PASS &&
    step === SEASON_PASS_STEPS.SHOWTIMES;

  return (
    <>
      {shoulShowSeasonPassShowtimesTopBar && (
        <SeasonPassShowtimesTopBar seasonPassBarHeight={seasonPassBarHeight} />
      )}
      <Flex
        className='header-container'
        data-testid='header-container'
        sx={{
          mt: shoulShowSeasonPassShowtimesTopBar
            ? `${seasonPassBarHeight}px`
            : 0,
          py: 5,
          justifyContent: 'space-between',
          alignItems: 'center',
          flexShrink: 1,
          button: {
            color: 'mostReadableOnWebsiteBackground',
            svg: {
              '.icon': {
                fill: 'mostReadableOnWebsiteBackground',
              },
            },

            '&:hover': {
              svg: {
                '.icon': {
                  fill: 'primary',
                },
              },
            },
          },
        }}
      >
        <Flex
          className='header-button-container'
          sx={{ pl: 4, width: '100px', justifyContent: 'flex-start' }}
        >
          <GoBackButton />
        </Flex>
        <Logo />
        <Flex
          className='header-button-container'
          sx={{ pr: 4, width: '100px', justifyContent: 'flex-end' }}
        >
          <ExtraMenu />
        </Flex>
      </Flex>
    </>
  );
};

export default HeaderContainer;
