/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { Concession, OrderDelivery, Deal } from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { PEACH_CODES } from '../../../constants';
import useAnalyticsTrackOnce from '../../../hooks/useAnalyticsTrackOnce';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useMaintainDeliveryItemCount } from '../../../hooks/useMaintainDeliveryItemCount';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { setGiftCardConcessions } from '../../../services/GiftCardHelpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectLoading,
  selectPhysicalGiftCardRecipient,
  selectQueryString,
  selectToken,
  selectSelectedGiftCards,
  selectPhysicalGiftCardGroup,
  selectGiftCardsAdded,
  selectAllConcessions,
  selectContentPhysicalGiftcards,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import DeliveryDetails from '../../common/concessionsonlycommon/physicalgiftcard/DeliveryDetailsPhysical';
import SinglePhysicalGiftCardOptionRow from '../../common/concessionsonlycommon/physicalgiftcard/SinglePhysicalGiftCardOptionRow';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';

const PhysicalGiftCardSelect: React.FC = () => {
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();

  const bookingData = useSelector(selectBookingData);
  const concessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const loading = useSelector(selectLoading);
  const physicalGiftCardRecipient = useSelector(
    selectPhysicalGiftCardRecipient
  );
  const queryString = useSelector(selectQueryString);
  const selectedGiftCards = useSelector(selectSelectedGiftCards);
  const physicalGiftCardGroup = useSelector(selectPhysicalGiftCardGroup);
  const giftCardsAddedToPos = useSelector(selectGiftCardsAdded);
  const contentPhysicalGiftCards = useSelector(selectContentPhysicalGiftcards);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);

  const selectedGiftCardsList = selectedGiftCards.list.filter(
    (concession) => !concession.isDeliveryItem
  );

  const hasGiftCards = selectedGiftCardsList
    ? selectedGiftCardsList.some((x: Concession) => x.quantity > 0)
    : false;

  const totalCardsSelected = selectedGiftCardsList.reduce(
    (a: number, b: Concession) => a + (b['quantity'] || 0),
    0
  );

  useMaintainDeliveryItemCount(totalCardsSelected);

  useAnalyticsTrackOnce(TrackingEvent.GIFTCARD_LANDING);

  // gets Data
  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/GiftCard/GetPhysicalGiftCards/${bookingData.circuitId}/${bookingData.cinemaId}`;
      const response = await backend.get(path);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        const deals = response.content.deals?.filter(
          (x: Deal) => x.isConcessionRelated && !x.isTicketRelated
        );
        if (deals) {
          dispatch(actionCreators.setDeals(deals));
        }
        setGiftCardConcessions(
          response.content.listConcessionGrouping,
          queryString,
          dispatch
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!concessions && bookingData) {
      getConcessionData();
    }
  }, [bookingData, concessions, dispatch, queryString]);

  const addGiftCardsSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasGiftCards || giftCardsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: false,
        isGiftWrapped: false,
        giftMessage: physicalGiftCardRecipient.message,
        deliveryAddress: {
          name: physicalGiftCardRecipient.name,
          address1: physicalGiftCardRecipient.addressLineOne,
          address2: physicalGiftCardRecipient.addressLineTwo,
          town: physicalGiftCardRecipient.city,
          postcode: physicalGiftCardRecipient.postcode,
          state: physicalGiftCardRecipient.state ?? '',
        },
        billingAddress: {
          name: customer.name,
          email: customer.email,
        },
      };

      selectedGiftCards?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedGiftCards
          ? selectedGiftCards.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/GiftCard/AddPhysicalGiftCards`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setGiftCardsAddedToPos(hasGiftCards));
        dispatch(actionCreators.setBookingFee(response.content.bookingFee));
        dispatch(
          actionCreators.setGrandTotalWithDiscount(
            response.content.grandTotalWithDiscount,
            response.content.totalDiscount
          )
        );
        dispatch(actionCreators.setOrderExists());
        boostNavigate.navigateToNextStep();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addGiftCardsSelectionToPos();
  };

  const getPhysicalGiftCardsRows = () => {
    return physicalGiftCardGroup?.items?.map((concession: Concession) => (
      <SinglePhysicalGiftCardOptionRow
        {...concession}
        key={concession.id}
        defaultImage={content.physicalGiftCards.physicalGiftCardDefaultImage}
        totalCardsSelected={totalCardsSelected}
      />
    ));
  };

  if (!contentPhysicalGiftCards || !config) return null;

  return (
    <Box
      className='giftcards'
      data-testid='giftcards'
      sx={{ textAlign: 'center' }}
    >
      <h1>{contentPhysicalGiftCards.stepOneHeading}</h1>
      <ContainedRow>
        <h2 sx={{ mt: 5, textAlign: 'center' }}>
          1. {contentPhysicalGiftCards.selectGiftCardsHeading}
        </h2>
        <RichText text={contentPhysicalGiftCards.selectGiftCardsRichText} />
      </ContainedRow>

      {physicalGiftCardGroup && physicalGiftCardGroup.items.length > 0 ? (
        <Box mx={[0, 0, 8]}>
          <Box
            className='giftcard-rows-container'
            sx={{ my: 6, px: [0, 0, 3] }}
          >
            {getPhysicalGiftCardsRows()}
          </Box>

          {!showRecipientForm ? (
            <ActionButton
              disabled={!hasGiftCards}
              mt={7}
              mx={5}
              onClick={() => setShowRecipientForm(true)}
              sticky={false}
              showIcon={true}
              contained
              variant='primary'
            >
              {contentPhysicalGiftCards.continueToDetailsButtonText}
            </ActionButton>
          ) : (
            <>
              <DeliveryDetails isPageValidated={validateForm} />
              <ContainedRow>
                <RichText text={contentPhysicalGiftCards.additionalRichText} />
              </ContainedRow>

              <ActionButton
                mt={7}
                mx={5}
                sticky={false}
                showIcon={true}
                contained
                onClick={handleContinueToPaymentClick}
                disabled={
                  !hasGiftCards ||
                  !physicalGiftCardRecipient.isValid ||
                  !customer.isValid
                }
                stickyMobileDesktop={false}
                variant='primary'
              >
                {contentPhysicalGiftCards.continueToPaymentButtonText}
              </ActionButton>
            </>
          )}
        </Box>
      ) : (
        !loading && (
          <ContainedRow styles={{ mt: 5 }}>
            <Box className='warning-container' p={5}>
              <p>{contentPhysicalGiftCards.noPhysicalGiftCardsAvailableText}</p>
            </Box>
          </ContainedRow>
        )
      )}
    </Box>
  );
};

export default PhysicalGiftCardSelect;
