/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';

import {
  selectConfig,
  selectContent,
  selectRegisterForCarParkingEncryptedToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ContainedRow from '../layout/ContainedRow';
import RichText from '../richtext/RichText';

const RegisterForCarParkingSection: React.FC = () => {
  const { currentCinema: currentCinemaConfig } = useSelector(selectConfig);
  const { confirmation: confirmationContent } = useSelector(selectContent);
  const registerForCarParkingEncryptedToken = useSelector(
    selectRegisterForCarParkingEncryptedToken
  );

  const handleButtonClick = () => {
    window.location.href = `${currentCinemaConfig.registerForCarParkingSiteUrl}${registerForCarParkingEncryptedToken}`;
  };

  if (
    !currentCinemaConfig.showRegisterForCarParking ||
    !registerForCarParkingEncryptedToken
  ) {
    return null;
  }

  return (
    <>
      <ContainedRow>
        <h2 sx={{ mt: 7 }}>{confirmationContent.registerForCarParkingTitle}</h2>
        <RichText
          text={confirmationContent.registerForCarParkingRichText}
          styles={{ textAlign: 'left' }}
        />
      </ContainedRow>

      <ActionButton
        showIcon
        contained
        mx={5}
        onClick={handleButtonClick}
        variant='primary'
      >
        {confirmationContent.registerForCarParkingButtonText}
      </ActionButton>
    </>
  );
};

export default RegisterForCarParkingSection;
