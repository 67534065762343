/** @jsxImportSource theme-ui */
import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Box, Flex } from 'theme-ui';

import { Concession, OrderDelivery } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectCustomer,
  selectToken,
  selectVoucherRecipient,
  selectEVouchersGroup,
  selectConcessionsAdded,
  selectAllConcessions,
  selectSelectedGiftCards,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import RichText from '../../common/richtext/RichText';
import { costWidth, quantityWidth } from '../../common/voucherstore/Constants';
import DeliveryDetails from '../../common/voucherstore/DeliveryDetails';
import VoucherKiosk from '../../common/voucherstore/VoucherKiosk';

const VouchersSelect: React.FC = () => {
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();
  const availableConcessions = useSelector(selectAllConcessions);
  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const customer = useSelector(selectCustomer);
  const dataToken = useSelector(selectToken);
  const selectedConcessions = useSelector(selectSelectedGiftCards);
  const voucherRecipient = useSelector(selectVoucherRecipient);
  const eVoucherGroup = useSelector(selectEVouchersGroup);
  const concessionsAddedToPos = useSelector(selectConcessionsAdded);
  const [showRecipientForm, setShowRecipientForm] = useState<boolean>(false);
  const [validateForm, setValidateForm] = useState<boolean>(false);
  const [noConcessionReturn, setNoConcessionReturn] = useState<boolean>(false);

  useEffect(() => {
    const getConcessionData = async () => {
      dispatch(actionCreators.setLoading(true));
      const path = `api/EVouchers/Get/${bookingData.cinemaId}`;
      const data = {
        dataToken: dataToken,
      };
      const response = await backend.post(path, data);
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
        if (response.content.listConcessionGrouping.count === 0) {
          setNoConcessionReturn(true);
        }
      } else {
        setNoConcessionReturn(true);
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!availableConcessions && bookingData && dataToken) {
      getConcessionData();
    }
  }, [
    availableConcessions,
    dispatch,
    bookingData,
    noConcessionReturn,
    config?.circuitId,
    dataToken,
  ]);

  const hasConcessions = selectedConcessions
    ? selectedConcessions.list.some((x: Concession) => x.quantity > 0)
    : false;

  const addVouchersSelectionToPos = async () => {
    dispatch(actionCreators.setLoading(true));

    if (hasConcessions || concessionsAddedToPos) {
      const orderDelivery: OrderDelivery = {
        isGift: true,
        isGiftWrapped: false,
        giftMessage: voucherRecipient.message,
        deliveryAddress: {
          name: voucherRecipient.name,
          email: voucherRecipient.email,
        },
        billingAddress: {
          email: customer.email,
          name: customer.name,
        },
      };

      selectedConcessions?.list.forEach((c: Concession) => {
        c.orderDelivery = orderDelivery;
      });

      const data = {
        dataToken: dataToken,
        concessions: selectedConcessions
          ? selectedConcessions.list.filter((c: Concession) => !c.hidden)
          : [],
      };
      const response = await backend.post(
        `api/EVouchers/Post/${bookingData.cinemaId}`,
        data
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(actionCreators.setToken(response.content.dataToken));
        dispatch(actionCreators.setConcessionsAddedToPos(hasConcessions));
        dispatch(actionCreators.setOrderExists());
        boostNavigate.navigateToNextStep();
      } else {
        dispatch(
          actionCreators.setError(
            content.error.concessionsCouldNotBeAddedRichText,
            PEACH_CODES.concessionsCouldNotBeAdded
          )
        );
      }
    }
    dispatch(actionCreators.setLoading(false));
  };

  const handleContinueToPaymentClick = () => {
    if (!validateForm) {
      setValidateForm(true);
    }
    addVouchersSelectionToPos();
  };

  if (!content?.vouchers || !config) return null;

  return (
    <div className='giftcards' data-testid='vouchers'>
      <ContainedRow>
        <h1 sx={{ textAlign: 'center' }}>
          {content.vouchers.selectVouchersHeading}
        </h1>
        <RichText text={content.vouchers.instructionsRichText} />
      </ContainedRow>

      {eVoucherGroup ? (
        <Flex sx={{ justifyContent: 'center', mx: -3 }}>
          <Box
            className='loyalty-rows-container'
            sx={{ mt: 6, maxWidth: '800px', width: '100%' }}
          >
            <Box
              className='header-row'
              sx={{ px: 3, pt: 4, mt: 6, display: ['none', 'none', 'flex'] }}
            >
              <Box sx={{ flexGrow: 1, textAlign: 'left' }}>
                {content.kiosk.itemsLabel}
              </Box>
              <Box sx={{ textAlign: 'center', width: quantityWidth }}>
                {content.kiosk.qtyLabel}
              </Box>
              <Box sx={{ textAlign: 'right', width: costWidth }}>
                {content.kiosk.priceLabel}
              </Box>
            </Box>
            <VoucherKiosk />
            {!showRecipientForm ? (
              <ActionButton
                disabled={!hasConcessions}
                mt={7}
                mx={5}
                onClick={() => setShowRecipientForm(true)}
                sticky={false}
                showIcon={true}
                contained
                variant='primary'
              >
                {content.vouchers.voucherSelectionContinueButtonText}
              </ActionButton>
            ) : (
              <>
                <DeliveryDetails isPageValidated={validateForm} />
                <ActionButton
                  mt={7}
                  mx={5}
                  sticky={false}
                  showIcon={true}
                  contained
                  onClick={handleContinueToPaymentClick}
                  disabled={
                    !hasConcessions ||
                    !voucherRecipient.isValid ||
                    !customer.isValid
                  }
                  stickyMobileDesktop={false}
                  variant='primary'
                >
                  {content.vouchers.recipientDetailsContinueButtonText}
                </ActionButton>
              </>
            )}
          </Box>
        </Flex>
      ) : (
        <ContainedRow styles={{ mt: 5 }}>
          <Box className='warning-container' p={5}>
            <p>{content.vouchers.noVouchersMessage}</p>
          </Box>
        </ContainedRow>
      )}
    </div>
  );
};

export default VouchersSelect;
