/** @jsxImportSource theme-ui */
import React, { ReactElement, useEffect, useState } from 'react';

import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { Box, Flex } from 'theme-ui';

import { Concession, ConcessionGrouping } from '../../../@types/modelTypes';
import { PEACH_CODES } from '../../../constants';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useValidateConcessionsJourney } from '../../../hooks/useValidateConcessionsJourney';
import { buildQueryString } from '../../../services/QueryStringHelper';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectSelectedFaBConcessions,
  selectAllConcessions,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import ContainedRow from '../../common/layout/ContainedRow';
import TierItem from '../../common/loyalty/TierItem';
import RichText from '../../common/richtext/RichText';

const SelectSignupTier: React.FC = () => {
  const dispatch = useDispatch();
  useValidateConcessionsJourney();
  const boostNavigate = useBoostNavigate();
  const [searchParams] = useSearchParams();
  const [cookies] = useCookies();
  const availableConcessions = useSelector(selectAllConcessions);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const selectedConcessions = useSelector(selectSelectedFaBConcessions);
  const [freeConcessionSelected, setFreeConcessionSelected] =
    useState<boolean>(false);

  const hasConcessions = availableConcessions?.some(
    (x: ConcessionGrouping) => !!x.items.length
  );

  const hasSelection =
    selectedConcessions?.list.length > 0 &&
    selectedConcessions?.list.some((x) => x.quantity >= 1);

  useEffect(() => {
    setFreeConcessionSelected(
      !!(
        selectedConcessions?.list.length > 0 &&
        selectedConcessions?.list.filter((x) => x.cost < 100).length > 0
      )
    );
  }, [selectedConcessions, setFreeConcessionSelected]);

  useEffect(() => {
    const getData = async () => {
      dispatch(actionCreators.setLoading(true));
      const qs = buildQueryString({
        circuitId: config.circuitId,
      });
      const response = await backend.get(
        `api/Member/GetSignUpConcessions${qs}`
      );
      if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
        dispatch(
          actionCreators.setConcessions(response.content.listConcessionGrouping)
        );
      }
      dispatch(actionCreators.setLoading(false));
    };
    if (!availableConcessions) {
      getData();
    }
  }, [
    availableConcessions,
    config?.circuitId,
    cookies,
    dispatch,
    searchParams,
  ]);

  const navigateToNextPath = () => {
    boostNavigate.navigateToNextStep();
  };

  useEffect(() => {
    const loyaltyGroups: ConcessionGrouping[] | null | undefined =
      availableConcessions?.filter((x: ConcessionGrouping) => !!x.items.length);
    loyaltyGroups?.forEach((loyaltyGroup: ConcessionGrouping) => {
      if (!hasSelection) {
        loyaltyGroup.items
          .filter((x) => x.cost < 100)
          .forEach((concession: Concession) => {
            concession.cost = 0;
            dispatch(actionCreators.setLoyaltyTierSelection(concession, 'add'));
          });
      }
    });
  }, [availableConcessions, dispatch, hasSelection]);

  const renderSignUpItems = () => {
    const loyaltyGroups: ConcessionGrouping[] | null | undefined =
      availableConcessions?.filter((x: ConcessionGrouping) => !!x.items.length);
    const list: ReactElement[] = [];
    loyaltyGroups?.forEach((loyaltyGroup: ConcessionGrouping) => {
      loyaltyGroup.items.forEach((concession: Concession) => {
        list.push(<TierItem {...concession} key={concession.id} />);
      });
    });
    return list;
  };

  if (!content?.loyalty) return null;

  return (
    <div className='loyalty' data-testid='loyalty'>
      <ContainedRow>
        <h1 sx={{ textAlign: 'center' }}>
          {content.loyalty.signupSelectTierHeading}
        </h1>
      </ContainedRow>

      <ContainedRow styles={{ mt: 4 }}>
        <h2 sx={{ textAlign: 'center' }}>
          {content.loyalty.signUpSelectionBoxHeading}
        </h2>
        <RichText
          text={content.loyalty.signupSelectTierInstructionsRichText}
          styles={{ textAlign: 'center' }}
        />
      </ContainedRow>

      {hasConcessions ?? (
        <ContainedRow styles={{ mt: 5 }}>
          <Box className='warning-container' sx={{ p: 5 }}>
            <p>{content.loyalty.noUpgradesMessage}</p>
          </Box>
        </ContainedRow>
      )}

      <Flex sx={{ justifyContent: 'center', mx: -3 }}>
        <Box
          className='loyalty-rows-container'
          sx={{ mt: 6, maxWidth: '800px', mx: 'auto', width: '100%' }}
        >
          {renderSignUpItems()}
        </Box>
      </Flex>

      <ContainedRow styles={{ mt: 6 }}>
        <ActionButton
          onClick={navigateToNextPath}
          stickyMobileDesktop={false}
          showIcon
          contained
          mx={5}
          variant='primary'
        >
          {freeConcessionSelected
            ? content.loyalty.signupFreeTierSelectedContinueButton
            : content.loyalty.signupPaidTierSelectedContinueButton}
        </ActionButton>
      </ContainedRow>
    </div>
  );
};

export default SelectSignupTier;
