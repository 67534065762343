import { defineMessages } from 'react-intl';

export default defineMessages({
  atLabel: {
    id: 'global.at-label',
    defaultMessage: 'At',
  },
  onLabel: {
    id: 'global.on-label',
    defaultMessage: 'On',
  },
  ofLabel: {
    id: 'global.of-label',
    defaultMessage: 'of',
  },
  selectedLabel: {
    id: 'global.selected-label',
    defaultMessage: 'selected',
  },
  includesLabel: {
    id: 'global.includes-label',
    defaultMessage: 'includes',
  },
  yesButtonText: {
    id: 'global.yes-button-text',
    defaultMessage: 'Yes',
  },
  noButtonText: {
    id: 'global.no-button-text',
    defaultMessage: 'No',
  },
  cancelButtonText: {
    id: 'global.cancel-button-text',
    defaultMessage: 'Cancel',
  },
  selectButtonText: {
    id: 'global.select-button-text',
    defaultMessage: 'Select',
  },
  closeButtonText: {
    id: 'global.close-button-text',
    defaultMessage: 'Close',
  },
  checkButtonText: {
    id: 'global.check-button-text',
    defaultMessage: 'Check',
  },
});
