import React, { useState } from 'react';

import classNames from 'classnames';
import { Form } from 'react-bootstrap';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import VoucherTicketType from './VoucherTicketType';

import { QuantitySelectorContext } from '../../../@types/actionTypes';
import {
  TicketTypeModel,
  GroupedTicketTypes,
} from '../../../@types/modelTypes';
import { TrackingEvent } from '../../../@types/trackingTypes';
import { VueEventTrackingStrategy } from '../../../analytics/vueEventTrackingStrategy';
import { PEACH_CODES } from '../../../constants';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectContent,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectState,
  selectTicketTypes,
  selectToken,
} from '../../../store/Selectors';
import ActionButton from '../actionbutton/ActionButton';
import ActionButtonSpinner from '../actionbuttonspinner/ActionButtonSpinner';
import BorderedCollapse from '../borderedcollapse/BorderedCollapse';

const SUCESSFULLYAPPLIED = 'SUCESSFULLYAPPLIED';
const ALREADYAPPLIED = 'ALREADYAPPLIED';
const NOTRECOGNISED = 'NOTRECOGNISED';
const SERVERERROR = 'SERVERERROR';

interface Props {
  orderHasMaxTickets: boolean;
}

const VoucherSelector: React.FC<Props> = ({ orderHasMaxTickets }) => {
  const dispatch = useDispatch();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const globalState = useSelector(selectState);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const ticketTypes = useSelector(selectTicketTypes);
  const token = useSelector(selectToken);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);

  const [disableApplyButton, setDisableApplyButton] = useState(false);
  const [feedback, setFeedback] = useState<string | undefined>(undefined);
  const [showVoucherForm, setShowVoucherForm] = useState(
    ticketTypes
      ? ticketTypes.ticketTypeModels.some((t) => t.voucherCode)
      : false
  );
  const [voucherInput, setVoucherInput] = useState('');
  const selectedSeats = useSelector(selectSelectedSeats);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  if (!ticketTypes) return null;

  const updateTicketTypes = (ticketTypeModels: TicketTypeModel[]) => {
    dispatch(actionCreators.setTicketTypes({ ticketTypeModels }));
  };

  const trackVoucherEvent = (event: TrackingEvent, discountCode: string) => {
    const vueTracking = new VueEventTrackingStrategy();
    vueTracking.processEvent(event, globalState, {
      pathName: location.pathname,
      dataLayerName: 'adobeDataLayer',
      discountCode,
    });
  };

  const addVoucher = async (voucherCode: string) => {
    if (!executeRecaptcha) return;

    const recaptchaToken = await recaptcha?.getRecaptchaToken(
      'AddVoucherCode',
      executeRecaptcha
    );

    const turnstileToken = await turnstile?.getToken();

    const unlockedRedemptionTicketIds: string[] = ticketTypes.ticketTypeModels
      .filter((x) => x.isRedemptionTicket)
      .map((x) => x.id);
    const data = {
      voucherCode,
      dataToken: token,
      recaptchaToken: recaptchaToken ?? null,
      unlockedRedemptionTicketIds,
    };
    const response = await backend.post(
      'api/Tickets/AddVoucherCode',
      data,
      turnstileToken
    );
    if (response.ok) {
      const responseContent = response.content;
      if (responseContent.peachCode === PEACH_CODES.noError) {
        const voucherTicketGroups: GroupedTicketTypes[] =
          response.content.groupedTicketTypes;
        const ticketTypeModels = ticketTypes.ticketTypeModels;
        voucherTicketGroups.forEach((gpt) => {
          gpt.ticketTypeModels.forEach((t) => {
            t.validatedTopVoucher = { voucherCode: t.voucherCode };
            t.voucherDisplayName = `${t.displayName} (${t.voucherCode.substring(
              0,
              4
            )}...${t.voucherCode.slice(-4)})`;
            ticketTypeModels.push(t);
          });
        });
        updateTicketTypes(ticketTypeModels);
        setFeedback(SUCESSFULLYAPPLIED);
        setVoucherInput('');
        trackVoucherEvent(TrackingEvent.VOUCHER_CODE_VALID, voucherCode);
      } else {
        setFeedback(NOTRECOGNISED);
        trackVoucherEvent(TrackingEvent.VOUCHER_CODE_INVALID, voucherCode);
      }
    } else {
      setFeedback(SERVERERROR);
    }

    turnstile?.resetToken();

    setDisableApplyButton(false);
  };

  const handleVoucherCheck = async () => {
    if (!voucherInput) return;
    setDisableApplyButton(true);
    const exists = ticketTypes.ticketTypeModels.some(
      (item) => item.voucherCode === voucherInput
    );
    if (!exists) {
      addVoucher(voucherInput);
    } else {
      setDisableApplyButton(false);
      setFeedback(ALREADYAPPLIED);
    }
  };

  const handleVoucherClick = (
    ticketTypeId: TicketTypeModel['id'],
    voucherCode: TicketTypeModel['voucherCode'],
    context: QuantitySelectorContext
  ) => {
    const { ticketTypeModels } = ticketTypes;
    const ticketTypeModel = ticketTypeModels.find(
      (x) => x.id === ticketTypeId && x.voucherCode === voucherCode
    );

    if (!ticketTypeModel) {
      return;
    }

    if (context === 'add') {
      ticketTypeModel.quantity += 1;
    } else {
      ticketTypeModel.quantity += -1;

      if (!isSeatsFirstJourney) {
        dispatch(actionCreators.removeAllSeats());
      }
    }

    updateTicketTypes(ticketTypeModels);
  };

  const hasTicketsWithTheSameVoucherAlreadyAdded = (
    voucherCode: TicketTypeModel['voucherCode'],
    ticketMaxQuantity: TicketTypeModel['maxQuantity']
  ) => {
    const ticketsWithTheSameVoucher = ticketTypes.ticketTypeModels.filter(
      (t) => t.voucherCode === voucherCode
    );

    if (!ticketsWithTheSameVoucher.length) {
      return false;
    }

    const ticketsWithTheSameVoucherSelectedQuantity =
      ticketsWithTheSameVoucher.reduce((a, b) => a + (b.quantity || 0), 0);

    return ticketMaxQuantity === ticketsWithTheSameVoucherSelectedQuantity;
  };

  const shouldDisplayTicket = (t: TicketTypeModel) => {
    return (
      t.voucherCode &&
      !t.isThirdPartyMemberVoucher &&
      (!journeyTypeConfig.isSeatsFirst ||
        selectedSeats.some((x) => x.areaCategoryCode === t.areaCategoryCode))
    );
  };

  return (
    <BorderedCollapse
      closeButtonText={content.payment.closeButtonText}
      heading={content.tickets.voucherHeading}
      setShow={setShowVoucherForm}
      show={showVoucherForm}
    >
      <div className='voucher-container' data-testid='voucher-selector'>
        <div className='voucher-selector'>
          <Form>
            <Form.Label
              className={classNames(disableApplyButton && 'disabled')}
            >
              {content.tickets.voucherLabel}
            </Form.Label>
            <Box
              sx={{
                justifyContent: 'space-between',
                display: ['block', 'flex'],
              }}
            >
              <Box sx={{ pr: [0, 3], flexGrow: 1 }}>
                <Form.Control
                  id='voucherNumber'
                  name='voucherNumber'
                  type='text'
                  placeholder={content.tickets.voucherPlaceholder}
                  onChange={(e) => {
                    setVoucherInput(e.target.value);
                  }}
                  value={voucherInput}
                  disabled={disableApplyButton}
                />
              </Box>
              <Box sx={{ flexShrink: 0, mt: [4, 0] }}>
                <ActionButton
                  onClick={handleVoucherCheck}
                  disabled={disableApplyButton}
                  variant='secondary'
                  mb={0}
                  mt={0}
                >
                  {disableApplyButton ? (
                    <ActionButtonSpinner />
                  ) : (
                    content.checkButtonText
                  )}
                </ActionButton>
              </Box>
            </Box>
          </Form>
          {feedback && feedback !== SUCESSFULLYAPPLIED && (
            <Box className='warning-container' sx={{ mt: 5, p: 5 }}>
              <p>
                {feedback === ALREADYAPPLIED
                  ? content.tickets.voucherAlreadyAppliedText
                  : content.tickets.voucherErrorText}
              </p>
            </Box>
          )}
          <div className='voucher-list'>
            {ticketTypes?.ticketTypeModels
              ?.filter((t) => shouldDisplayTicket(t))
              ?.map((t) => (
                <VoucherTicketType
                  key={`${t.id}${t.voucherCode}`}
                  ticket={t}
                  onClick={handleVoucherClick}
                  disableAdd={
                    orderHasMaxTickets ||
                    t.quantity === t.maxQuantity ||
                    hasTicketsWithTheSameVoucherAlreadyAdded(
                      t.voucherCode,
                      t.maxQuantity
                    )
                  }
                  packageTicketsIncludesLabel={
                    content.tickets.packageTicketsIncludesLabel
                  }
                  hideTax={config.currentCinema.hideTax}
                  disableRemove={t.quantity === 0}
                />
              ))}
          </div>
        </div>
      </div>
    </BorderedCollapse>
  );
};

export default VoucherSelector;
