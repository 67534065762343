import React, { PropsWithChildren } from 'react';

import classnames from 'classnames';
import { Box, ThemeUIStyleObject } from 'theme-ui';

interface Props {
  classNameWrapper?: string;
  classNameInner?: string | undefined | boolean;
  dataTestId?: string;
  key?: string;
  styles?: ThemeUIStyleObject;
  innerStyles?: ThemeUIStyleObject;
  textAlign?: 'left' | 'center' | 'right' | 'inherit';
}

const ContainedRow: React.FC<PropsWithChildren<Props>> = ({
  children,
  classNameWrapper,
  classNameInner,
  dataTestId,
  key,
  styles,
  innerStyles,
  textAlign,
}) => {
  return (
    <Box
      className={classNameWrapper}
      data-testid={dataTestId}
      key={key}
      sx={{ ...styles, textAlign: textAlign ?? undefined }}
    >
      <Box
        className={classnames('contained', classNameInner)}
        px={3}
        sx={innerStyles}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ContainedRow;
