/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Analytics, AnalyticsInstance, PageData } from 'analytics';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { adobeAnalyticsName, customAdobeAnalytics } from './adobeAnalytics';
import { customGoogleTagManager } from './customGoogleTagManager';
import {
  gaName,
  googleAnalyticsWithTracking,
} from './googleAnalyticsWithTracking';
import movioPlugin, { movioName } from './movioPlugin';
import tbcoReferralPlugin from './tbcoReferralPlugin';

import { GoogleAnalyticsOptions } from '../@types/analyticsTypes';
import {
  selectConfig,
  selectConsent,
  selectDidomiReady,
  selectQueryString,
  selectState,
} from '../store/Selectors';

const gtmName = 'google-tag-manager';

export interface ProviderProps {
  children: React.ReactNode;
}

const AnalyticsContext = createContext<AnalyticsInstance | null>(null);

const addGoogleAnalytics = (
  plugins: Record<string, unknown>[],
  gTagId: string,
  globalSiteTagId: string
) => {
  const gaMeasurementIds: string[] = [];
  if (gTagId) {
    gaMeasurementIds.push(gTagId);
  }
  if (globalSiteTagId) {
    gaMeasurementIds.push(globalSiteTagId);
  }
  if (gaMeasurementIds.length) {
    const config: GoogleAnalyticsOptions = {
      measurementIds: gaMeasurementIds,
      dataLayerName: 'dataLayer',
      gtagConfig: { send_page_view: true },
      debug: true,
    };
    plugins.push(googleAnalyticsWithTracking(config));
  }
};

export const AnalyticsProvider: React.FC<ProviderProps> = ({ children }) => {
  const { pathname } = useLocation();
  const [analytics, setAnalytics] = useState<AnalyticsInstance | null>(null);
  const didomiReady = useSelector(selectDidomiReady);
  const config = useSelector(selectConfig);
  const consent = useSelector(selectConsent);
  const queryString = useSelector(selectQueryString);
  const [plugins, setPlugins] = useState<Record<string, unknown>[]>([]);
  const globalState = useSelector(selectState);

  const hasGa = !!plugins.find((x) => x.name === gaName);
  const hasGtm = !!plugins.find((x) => x.name === gtmName);
  const hasMovio = !!plugins.find((x) => x.name === movioName);
  const hasAdobe = !!plugins.find((x) => x.name === adobeAnalyticsName);

  const globalStateRef = useRef(globalState);

  useEffect(() => {
    globalStateRef.current = globalState;
  }, [globalState]);

  // adds analytics
  useEffect(() => {
    if (config && !analytics && didomiReady) {
      const {
        referralTrackingUri,
        referralTrackingId,
        movioCustomerId,
        gtmTag,
        gTagId,
        globalSiteTagId,
        adobeAnalyticsTag,
      } = config;

      const plugins: Record<string, unknown>[] = [];

      if (adobeAnalyticsTag) {
        plugins.push(customAdobeAnalytics(adobeAnalyticsTag));
      }

      if (gtmTag) {
        plugins.push(customGoogleTagManager({ containerId: gtmTag }));
      }

      if (movioCustomerId) {
        plugins.push(movioPlugin({ movioCustomerId }));
      }

      if (referralTrackingUri) {
        plugins.push(
          tbcoReferralPlugin({
            referralTrackingUri,
            referralTrackingId,
          })
        );
      }
      addGoogleAnalytics(plugins, gTagId, globalSiteTagId);
      const analyticsInstance = Analytics({
        app: 'BoostTicketing',
        plugins: plugins,
        debug: true,
      });
      setPlugins(plugins);
      setAnalytics(analyticsInstance);
    }
  }, [analytics, config, didomiReady]);

  // enables/disables google analytics plugins
  useEffect(() => {
    if (!analytics || !consent || !config) return;

    if (consent.analytics) {
      hasMovio && analytics.plugins.enable(movioName);
      hasGa && analytics.plugins[gaName].enable(config.gTagId);
      hasAdobe && analytics.plugins.enable(adobeAnalyticsName);
    } else {
      hasMovio && analytics.plugins.disable(movioName, () => {});
      hasGa && analytics.plugins[gaName].disable(config.gTagId);
      hasAdobe && analytics.plugins.disable(adobeAnalyticsName, () => {});
    }
    if (consent.marketing) {
      hasGtm && analytics.plugins.enable(gtmName);
      hasGa && analytics.plugins[gaName].enable(config.globalSiteTagId);
    } else {
      hasGtm && analytics.plugins.disable(gtmName, () => {});
      hasGa && analytics.plugins[gaName].disable(config.globalSiteTagId);
    }
  }, [analytics, config, consent, hasGa, hasGtm, hasMovio, plugins, hasAdobe]);

  // sends pageview
  useEffect(() => {
    const globalState = globalStateRef.current;
    if (
      !analytics ||
      !consent?.analytics ||
      !globalState ||
      (!hasAdobe && !hasGa)
    )
      return;
    const pageData: PageData = {
      path: pathname,
      search: queryString,
      globalState: globalState,
    };
    analytics.page(pageData, {
      plugins: {
        all: false,
        gaWithTracking: true,
        adobeAnalytics: true,
      },
    });
  }, [analytics, hasGa, pathname, queryString, hasAdobe, consent?.analytics]);

  return (
    <AnalyticsContext.Provider value={analytics}>
      {children}
    </AnalyticsContext.Provider>
  );
};

export const useAnalytics = () => {
  return useContext(AnalyticsContext);
};
