/** @jsxImportSource theme-ui */
import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import SeatContainer from './SeatContainer';

import {
  SeatButtonStyle,
  SeatMapRow,
  SeatMapSeat,
} from '../../../../@types/modelTypes';
import {
  selectContentSeats,
  selectSeatsModel,
} from '../../../../store/Selectors';

const paddingForRowLabels = 60;
const halfHeightWidthRatio = 0.8333 / 2; // ration of height to width in SVGs

interface CoordinateStrategyProps {
  forceSeatText: boolean;
  handleSeatClick: (seat: SeatMapSeat) => void;
  seatButtonStyle: SeatButtonStyle;
  zoomContentWidth: number;
  overrideSoldAsGroup: boolean;
}

const CoordinateStrategy: React.FC<CoordinateStrategyProps> = ({
  forceSeatText,
  handleSeatClick,
  seatButtonStyle,
  zoomContentWidth,
  overrideSoldAsGroup,
}) => {
  const contentSeats = useSelector(selectContentSeats);
  const seatsModel = useSelector(selectSeatsModel);

  const [activeRowNumber, setActiveRowNumber] = useState<number | null>(null);

  if (!seatsModel.seatsLayoutModel.areas[0].areaDimensions) {
    return <div />;
  }
  const rows: SeatMapRow[] = seatsModel.seatsLayoutModel.rows;
  const seatMapAreaDimensions =
    seatsModel.seatsLayoutModel.areas[0].areaDimensions;
  const widthNoUnusedSpace =
    seatMapAreaDimensions.originalWidth -
    seatMapAreaDimensions.unusedLeft -
    seatMapAreaDimensions.unusedRight;
  const heightNoUsedSpace =
    seatMapAreaDimensions.originalHeight -
    seatMapAreaDimensions.unusedTop -
    seatMapAreaDimensions.unusedBottom;
  seatMapAreaDimensions.xRatio =
    (zoomContentWidth - paddingForRowLabels) / widthNoUnusedSpace;
  const widthToUse = widthNoUnusedSpace * seatMapAreaDimensions.xRatio;
  const heightToUse = heightNoUsedSpace * seatMapAreaDimensions.xRatio;

  const renderRowNames = () => {
    return rows
      .filter((r) => r.isARow)
      .map((row: SeatMapRow) => {
        const firstSeat = row.seats.find((s) => s.seatData);
        if (!firstSeat?.seatData || seatMapAreaDimensions.xRatio === undefined)
          return null;

        const top =
          (firstSeat?.seatData?.yPos -
            seatMapAreaDimensions.unusedTop +
            seatMapAreaDimensions.seatWidthAvg * halfHeightWidthRatio) *
          seatMapAreaDimensions.xRatio;

        return (
          <div
            key={row.physicalName}
            style={{
              top: `${top}px`,
              position: 'absolute',
            }}
          >
            <span className='visually-hidden'>{contentSeats.titleRowText}</span>
            {row.physicalName}
          </div>
        );
      });
  };

  const columns: React.ReactElement[] = [];
  {
    seatsModel.seatsLayoutModel.rows.forEach(
      (row: SeatMapRow, rowIndex: number) => {
        row.seats.forEach((seat: SeatMapSeat, i: number) => {
          if (seat.isASeat) {
            columns.push(
              <SeatContainer
                key={`${row.physicalName}_${rowIndex}_${seat.id}`}
                seat={seat}
                index={i}
                row={row}
                rowIndex={rowIndex}
                forceSeatText={forceSeatText}
                handleSeatClick={handleSeatClick}
                seatButtonStyle={seatButtonStyle}
                setActiveRowNumber={setActiveRowNumber}
                className={''}
                areaLabel={''}
                seatMapAreaDimensions={seatMapAreaDimensions}
                activeRow={seat.row === activeRowNumber}
                overrideSoldAsGroup={overrideSoldAsGroup}
              />
            );
          }
        });
      }
    );
  }

  const renderRowLabels = () => {
    return (
      <div
        className='row-label position-relative'
        sx={{
          width: `${paddingForRowLabels / 2}px`,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {renderRowNames()}
      </div>
    );
  };
  return (
    <div className='coordinate-strategy strategy-wrapper'>
      <div sx={{ width: `${zoomContentWidth}px`, display: 'flex' }}>
        {renderRowLabels()}
        <div
          className='seat-coordinate-containers'
          style={{
            position: 'relative',
            minHeight: `${heightToUse}px`,
            minWidth: `${widthToUse}px`,
          }}
        >
          {columns}
        </div>
        {renderRowLabels()}
      </div>
    </div>
  );
};
export default CoordinateStrategy;
