/** @jsxImportSource theme-ui */
import React from 'react';

import { useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import { selectContent } from '../../../../store/Selectors';
import ActionButton from '../../actionbutton/ActionButton';
import ContainedRow from '../../layout/ContainedRow';
import RichText from '../../richtext/RichText';
import LoginSection from '../LoginSection';
import Register from '../Register';

interface Props {
  handleContinueClick: () => void;
}

const GuestLoginOptions: React.FC<Props> = ({ handleContinueClick }) => {
  const content = useSelector(selectContent);
  return (
    <>
      <ContainedRow textAlign='center'>
        <h2 sx={{ mt: 6 }}>{content.signIn.loginAsMemberSubTitle}</h2>
        <RichText
          text={content.signIn.loginAsMemberRichText}
          styles={{ textWrap: 'balance' }}
        />
        <LoginSection />
      </ContainedRow>

      <ContainedRow styles={{ mt: 5 }}>
        <Box className='separator' mt={5} />
      </ContainedRow>

      <ContainedRow textAlign='center'>
        <h2 sx={{ mt: 7 }}>{content.signIn.continueAsGuestSubTitle}</h2>
        <RichText text={content.signIn.continueAsGuestRichText} />

        <ActionButton
          onClick={handleContinueClick}
          showIcon
          contained
          mx={5}
          variant='primary'
        >
          {content.signIn.continueAsGuestButtonText}
        </ActionButton>
      </ContainedRow>

      <Register />
    </>
  );
};

export default GuestLoginOptions;
