/** @jsxImportSource theme-ui */
import React from 'react';

import classnames from 'classnames';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';

import { LanguageCulture } from '../../../@types/configTypes';
import { getLanguageCultureName } from '../../../services/Helpers';
import backend from '../../../services/RestUtilities';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectConfig,
  selectToken,
  selectSelectedLanguageCulture,
} from '../../../store/Selectors';
import { ReactComponent as RadioSelectedSvg } from '../../../svgs/radioSelected.svg';
import { ReactComponent as RadioUnselectedSvg } from '../../../svgs/radioUnselected.svg';

interface Props {
  setIsDropdownOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MultiLanguage: React.FC<Props> = ({ setIsDropdownOpen }) => {
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_cookies, setCookies] = useCookies();

  const dispatch = useDispatch();

  const config = useSelector(selectConfig);
  const dataToken = useSelector(selectToken);
  const selectedLanguageCulture = useSelector(selectSelectedLanguageCulture);

  const sortedLanguages = config.availableLanguageCultures.sort(
    (a: LanguageCulture, b: LanguageCulture) => (a < b ? -1 : 1)
  );

  const getLanguageDisplayName = (culture: LanguageCulture) => {
    const cultureName = getLanguageCultureName(culture);
    return cultureName;
  };

  const setLanguage = async (culture: LanguageCulture) => {
    setIsDropdownOpen(false);
    dispatch(actionCreators.setLoading(true));

    const data = {
      dataToken: dataToken,
      selectedLanguageCulture: culture,
    };
    const response = await backend.post('api/Content/', data);
    const content = response.content.content;
    const dazzlerContent = response.content.dazzlerContent;
    const token = response.content.dataToken;

    if (response.ok) {
      dispatch(
        actionCreators.setLanguage(content, dazzlerContent, token, culture)
      );
      const cookieOptions = {
        path: '/',
        expires: moment().add(5, 'y').toDate(),
        secure: true,
        sameSite: true,
      };
      setCookies('preferredLanguageCulture', culture, cookieOptions);
    }

    dispatch(actionCreators.setLoading(false));
  };

  return (
    <div data-testid='multi-language' className='multi-language'>
      {sortedLanguages.map((culture, i) => (
        <button
          key={`${i}_${culture}`}
          onClick={() => setLanguage(culture)}
          className={classnames(
            'multi-lang-option',
            culture === selectedLanguageCulture && 'selected'
          )}
          sx={{
            width: '100%',
            backgroundColor: 'boxBackground',
            borderTop: 'boxSeparator',
            color: 'mostReadableOnWebsiteBackground',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            '&:hover': {
              backgroundColor: 'boxHoverBackground',

              '.checkbox-button .selectable-button-svg': {
                '& .a': { fill: 'boxHoverBackground' },
              },
            },
          }}
        >
          {getLanguageDisplayName(culture)}

          <div className='checkbox-button smaller selectable-button-svg'>
            {culture === selectedLanguageCulture ? (
              <RadioSelectedSvg className='selectable-button-svg' />
            ) : (
              <RadioUnselectedSvg className='selectable-button-svg' />
            )}
          </div>
        </button>
      ))}
    </div>
  );
};

export default MultiLanguage;
