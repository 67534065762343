/** @jsxImportSource theme-ui */
import React, { FC, memo, useEffect, useState } from 'react';

import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from 'theme-ui';

import LoyaltyTicketSelector from './LoyaltyTicketSelector';
import TicketSelectorContainer from './TicketSelectorContainer';

import { GlobalState } from '../../../@types/modelTypes';
import { useRecaptcha } from '../../../contextProviders/recaptchaContext';
import { useTurnstile } from '../../../contextProviders/turnstileContext';
import { useBoostNavigate } from '../../../hooks/useBoostNavigate';
import { useUpdateExpectedBookingFeesAndTax } from '../../../hooks/useUpdateExpectedBookingFeesAndTax';
import { useValidateJourney } from '../../../hooks/useValidateJourney';
import { getTotalNumberOfSeatsWithinSelectedTickets } from '../../../services/Helpers';
import { actionCreators } from '../../../store/ActionCreators';
import {
  selectBookingData,
  selectConfig,
  selectContent,
  selectContinueButtonText,
  selectIsSeatsFirstJourney,
  selectJourneyTypeConfig,
  selectSelectedSeats,
  selectTicketTypes,
} from '../../../store/Selectors';
import ActionButton from '../../common/actionbutton/ActionButton';
import CustomHtmlDiv from '../../common/customHtmlDiv/CustomHtmlDiv';
import ContainedRow from '../../common/layout/ContainedRow';
import RecaptchaText from '../../common/recaptchatext/RecaptchaText';
import SelectedHeading from '../../common/selectedHeading/SelectedHeading';
import DealsIntroduction from '../../common/tickets/DealsIntroduction';
import { WidgetData } from '../types';

type Props = {
  widget: WidgetData<'TicketingCMSJourneyTicketSelectionWidget'>;
};

export const TicketSelection: FC<Props> = ({ widget }) => {
  const dispatch = useDispatch();
  const boostNavigate = useBoostNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const recaptcha = useRecaptcha();
  const turnstile = useTurnstile();

  const bookingData = useSelector(selectBookingData);
  const config = useSelector(selectConfig);
  const content = useSelector(selectContent);
  const journeyTypeConfig = useSelector(selectJourneyTypeConfig);
  const selectedSeats = useSelector(selectSelectedSeats);
  const availablePosTickets = useSelector(
    (state: GlobalState) => state.availablePosTickets
  );

  const ticketTypes = useSelector(selectTicketTypes);
  const continueButtonText = useSelector(selectContinueButtonText);
  const isSeatsFirstJourney = useSelector(selectIsSeatsFirstJourney);

  const hasMemberTickets = ticketTypes?.ticketTypeModels.find(
    (t) => t.isMemberTicket
  );
  const [feedback, setFeedback] = useState<string | undefined>();

  useValidateJourney();

  // Show error when no tickets available
  useEffect(() => {
    if (
      !!content &&
      !!availablePosTickets &&
      !availablePosTickets.groupedTicketTypes
    ) {
      dispatch(actionCreators.setError(content.error.sessionExpiredRichText));
    }
  }, [content, availablePosTickets, dispatch]);

  const navigateToNextStep = () => {
    boostNavigate.navigateToNextStep({
      appendCinemaAndSessionIdsFromUrl: true,
    });
  };

  useUpdateExpectedBookingFeesAndTax();

  const validateDynamicBasket = async () => {
    setFeedback(undefined);

    dispatch(
      actionCreators.validateDynamicBasket({
        executeRecaptcha,
        setCeaTicketInsufficientFeedbackFunc: setFeedback,
        navigateToNextStepFunc: navigateToNextStep,
        turnstile,
        recaptcha,
      })
    );
  };

  const handleContinueClick = async () => {
    if (config.useDynamicBasket && !journeyTypeConfig.isSeatsFirst) {
      await validateDynamicBasket();
    } else if (ticketTypes) {
      dispatch(
        actionCreators.addBasketTickets({
          executeRecaptcha,
          setCeaTicketInsufficientFeedbackFunc: setFeedback,
          navigateToNextStepFunc: navigateToNextStep,
          turnstile,
          recaptcha,
        })
      );
    }
  };

  const numberOfTicketsSelected = ticketTypes
    ? getTotalNumberOfSeatsWithinSelectedTickets(ticketTypes.ticketTypeModels)
    : 0;

  const isSeatsFirst =
    isSeatsFirstJourney &&
    bookingData.isReservedSeating &&
    journeyTypeConfig.isSeatsFirst;
  const ticketSelectedForEverySeat =
    selectedSeats.length === numberOfTicketsSelected;
  const orderHasMaxTickets =
    (config.tickets.maxTicketsPerOrder &&
      numberOfTicketsSelected >= config.tickets.maxTicketsPerOrder) ||
    (isSeatsFirst && ticketSelectedForEverySeat);
  const continueDisabled = isSeatsFirst
    ? !ticketSelectedForEverySeat
    : !numberOfTicketsSelected;

  return (
    <Box>
      {isSeatsFirst && (
        <SelectedHeading
          allSelected={ticketSelectedForEverySeat}
          numberSelected={numberOfTicketsSelected}
          numberToSelect={selectedSeats.length}
        />
      )}

      {widget.shape?.enableMultipleLoyaltyCardTicketPurchase &&
        hasMemberTickets && (
          <>
            <RecaptchaText />
            <ContainedRow styles={{ mt: 5, textAlign: 'left' }}>
              <LoyaltyTicketSelector
                orderHasMaxTickets={orderHasMaxTickets}
                widget={widget}
              />
            </ContainedRow>
          </>
        )}

      <DealsIntroduction />

      {widget.shape?.customHtmlDivId && (
        <CustomHtmlDiv id={widget.shape?.customHtmlDivId} />
      )}

      <TicketSelectorContainer
        ticketTypeGroup={
          widget.shape?.enableMultipleLoyaltyCardTicketPurchase
            ? 'non-member-tickets'
            : 'all-tickets'
        }
        orderHasMaxTickets={orderHasMaxTickets}
        widget={widget}
      />

      <ActionButton
        onClick={handleContinueClick}
        disabled={continueDisabled}
        sticky={widget.shape?.useStickyButton}
        showIcon
        contained
        mx={5}
        showWarningMessage={!!feedback}
        warningMessage={feedback}
        variant='primary'
        showCartSummaryButtonOnMobile
      >
        {continueButtonText}
      </ActionButton>
    </Box>
  );
};

export default memo(TicketSelection);
