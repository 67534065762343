/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch } from 'react';

import classNames from 'classnames';
import moment from 'moment';
import { isEmail, isLength } from 'validator';

import { getContinueButtonTextForKioskEnabledJourney } from './ContinueButtonTextHelper';
import { journeyTypeConfigs } from './journeyTypeConfigs';
import { getContentForError } from './PeachErrorResolver';

import {
  ChangeGiftCardByIdContext,
  QuantitySelectorContext,
} from '../@types/actionTypes';
import {
  Config,
  ConfigTickets,
  CurrencyConfig,
  LanguageCulture,
} from '../@types/configTypes';
import {
  AttributeWarning,
  Content,
  MovieRatingWarning,
  ScreenWarning,
} from '../@types/contentTypes';
import {
  BookingFeeType,
  FoodAndBeverageDeliveryWindowsDisplayOptionEnum,
  FoodAndBeverageJourneyDeliveryType,
  OrderType,
} from '../@types/enums';
import {
  Concession,
  ConfirmationModel,
  ConfirmationSession,
  CreateRefundData,
  GlobalState,
  TicketTypes,
  BookingData,
  SelectedConcessions,
  ExperienceAttribute,
  TicketTypeModel,
  BookingFeeStrategy,
  Deal,
  DealDetails,
  DealInTicketsStep,
  GiftCard,
  AddConcessionRequestModel,
  DeliveryWindow,
  RefundData,
  SeatMapArea,
  SelectSeatsModel,
  SeasonPassTicketType,
  SelectedSeasonPassSession,
  SelectedSeasonPass,
  CartSummary,
  ConcessionGrouping,
} from '../@types/modelTypes';
import {
  CURRENCY_CULTURES,
  CURRENCY_ISO_CODES,
  CURRENCY_LEFT,
  JOURNEY_TYPES,
  LANGUAGE_CULTURE_NAMES,
  PEACH_CODES,
} from '../constants';
import * as StepPaths from '../constants/stepPaths';
import backend from '../services/RestUtilities';
import { actionCreators } from '../store/ActionCreators';

import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/da';
import 'moment/locale/pt';

export const getDisplayCountdown = (culture: LanguageCulture) => {
  let hrs, mins, secs;
  switch (culture) {
    case 'fr-FR':
      hrs = 'h';
      mins = 'min';
      secs = 's';
      break;
    case 'es-MX':
      hrs = 'Hrs';
      mins = 'Min';
      secs = 'Seg';
      break;
    case 'da-DK':
      hrs = 'Timer';
      mins = 'Min';
      secs = 'Sek';
      break;
    case 'pt-BR':
      hrs = 'h';
      mins = 'm';
      secs = 's';
      break;
    default:
      hrs = 'Hrs';
      mins = 'Mins';
      secs = 'Secs';
      break;
  }
  return { hrs, mins, secs };
};

export const shouldHideCartSummary = (pathname: string): boolean => {
  return (
    pathname.startsWith('/concessions/login') ||
    pathname.startsWith('/concessions/landing') ||
    pathname.startsWith('/loyalty/upgrade') ||
    pathname.startsWith('/giftcards/login') ||
    pathname.startsWith('/loyalty/login') ||
    pathname.startsWith('/embargo') ||
    pathname.startsWith('/error') ||
    pathname.includes('/login') ||
    pathname.startsWith('/signup/selecttier') ||
    pathname === '/signup' ||
    pathname.startsWith(`/${StepPaths.WARNING}`) ||
    pathname.startsWith(`/${StepPaths.STARTSEASONPASS}`) ||
    pathname.startsWith(`/${StepPaths.SEASONPASS}/${StepPaths.SELECT}`)
  );
};

export const timeFormat = (culture: LanguageCulture) => {
  switch (culture) {
    case 'fr-FR':
      return 'H:mm';
    case 'da-DK':
      return 'HH:mm';
    case 'pt-BR':
      return 'HH:mm';
    default:
      return 'h:mm A';
  }
};

export const getDisplayDateAndTime = (
  dateTime: Date,
  culture: LanguageCulture
) => {
  const countryCode = getLanguageFromCulture(culture);

  const dateFormat = (culture: LanguageCulture) => {
    switch (culture) {
      case 'en-GB':
        return 'dddd, D MMMM YYYY';
      case 'fr-FR':
        return 'dddd, D MMMM YYYY';
      case 'da-DK':
        return 'D. MMMM YYYY';
      case 'pt-BR':
        return 'dddd, LL';
      default:
        return 'dddd, MMMM D YYYY';
    }
  };

  const displayDate = moment(dateTime)
    .locale(countryCode)
    .format(dateFormat(culture));
  const displayTime = moment(dateTime)
    .locale(countryCode)
    .format(timeFormat(culture));
  return { displayDate, displayTime };
};

export const getCardExpiryDateString = (date: Date) => {
  return moment(date).format('MM/YY');
};

export const getLanguageCultureName = (culture: LanguageCulture) => {
  return LANGUAGE_CULTURE_NAMES[culture];
};

export const getGratuityInCents = (
  selectedGratuity: GlobalState['selectedGratuity'],
  selectedConcessions: GlobalState['selectedConcessions'],
  ticketTypes: GlobalState['ticketTypes']
) => {
  let gratuityInCents: number;

  if (selectedGratuity?.percentage > 0) {
    const cartCostInCents = getCartCostInCents(
      selectedConcessions.list,
      ticketTypes?.ticketTypeModels
    );

    gratuityInCents = Math.round(
      (cartCostInCents * selectedGratuity.percentage) / 100
    );
  } else {
    gratuityInCents = selectedGratuity?.amount;
  }

  return gratuityInCents;
};

export const calculateAmountToPayByBankCard = (
  appliedGiftCards: GiftCard[],
  grandTotal: number,
  donation: number,
  loyaltyRedemptionRewards?: number
) => {
  if (!loyaltyRedemptionRewards) {
    loyaltyRedemptionRewards = 0;
  }
  const appliedGiftCardsTotalBalance = appliedGiftCards?.reduce(
    (a, b) => a + b.giftCardBalanceInCents,
    0
  );

  const amountToPayWithoutDonation = grandTotal - donation;
  const amount = Math.max(
    amountToPayWithoutDonation -
      appliedGiftCardsTotalBalance -
      loyaltyRedemptionRewards,
    0
  );

  return amount + donation;
};

export const getProductsTax = (
  selectedConcessions: GlobalState['selectedConcessions'],
  ticketTypes: TicketTypes | null
) => {
  let taxTotal = ticketTypes
    ? ticketTypes.ticketTypeModels.reduce(
        (a, b) => a + (b.quantity > 0 ? b.tax * b.quantity : 0),
        0
      )
    : 0;

  selectedConcessions?.list &&
    selectedConcessions.list.forEach((concession: Concession) => {
      let concessionTax = concession.taxInCents;
      concession.modifierGroups?.forEach((mg) =>
        mg.modifiers.forEach((m) => {
          if (!mg.isSmart && m.quantity > 0) {
            concessionTax += m.quantity * m.taxInCents;
          }

          if (mg.isSmart && m.quantity > 1) {
            concessionTax += (m.quantity - 1) * m.taxInCents;
          }
        })
      );

      taxTotal += concessionTax * concession.quantity;
    });

  return taxTotal;
};

export const getCartCostInCents = (
  selectedConcessionsList: Concession[],
  ticketTypeModels: TicketTypeModel[] | undefined
) => {
  let cartCostInCents = 0;

  selectedConcessionsList?.forEach((concession: Concession) => {
    let concessionCost = concession.cost;
    concession.modifierGroups?.forEach((mg) =>
      mg.modifiers.forEach((m) => {
        if (!mg.isSmart && m.quantity > 0) {
          concessionCost += m.quantity * m.priceInCents;
        }
        if (mg.isSmart && m.quantity > 1) {
          concessionCost += (m.quantity - 1) * m.priceInCents;
        }
      })
    );
    concession.modifiers?.forEach((m) => {
      if (m.quantity > 0) {
        concessionCost += m.quantity * m.priceInCents;
      }
    });

    cartCostInCents += concessionCost * concession.quantity;
  });

  ticketTypeModels?.length &&
    ticketTypeModels.forEach((t) => {
      cartCostInCents += t.quantity * t.price;
    });

  return cartCostInCents;
};

export const getCountryCodeFromCulture = (
  languageCulture?: LanguageCulture
) => {
  if (!languageCulture) return 'us';
  if (languageCulture.includes('-')) {
    return languageCulture.split('-')[1];
  }
  return languageCulture;
};

export const getLanguageFromCulture = (languageCulture?: LanguageCulture) => {
  if (!languageCulture) return 'en';
  if (languageCulture.includes('-')) {
    return languageCulture.split('-')[0];
  }
  return languageCulture;
};

export const getPriceFromCentsWithDecimals = (price: number | null) => {
  if (!price || price === 0) {
    return '0.00';
  }
  return (price / 100).toFixed(2);
};

export const displayPriceFromDollars = (
  amount: number,
  currencySymbol: string
) => {
  return `${currencySymbol}${amount.toFixed(2)}`;
};

export const displayPrice = (
  amount: number,
  currencyConfig: CurrencyConfig | undefined
) => {
  if (!currencyConfig) {
    return '';
  }
  const currencySymbol: string = CURRENCY_ISO_CODES[currencyConfig.currency];
  const culture = CURRENCY_CULTURES[currencyConfig.culture];
  const price = getPriceFromCentsWithDecimals(amount).replace(
    '.',
    culture.decimalSeperator
  );
  const separator = culture.spaceBetween ? ' ' : '';
  return culture.position === CURRENCY_LEFT
    ? `${currencySymbol}${separator}${price}`
    : `${price}${separator}${currencySymbol}`;
};

export const displayPriceWithCurrencyCode = (
  amount: number,
  currencyConfig: CurrencyConfig | undefined
) => {
  return currencyConfig
    ? `${displayPrice(amount, currencyConfig)} ${currencyConfig.currency}`
    : '';
};

const getJourneyTypeFromOrderType = (
  confirmationSession: ConfirmationSession
) => {
  if (
    confirmationSession.confirmationData.orderType === OrderType.SeasonPass ||
    confirmationSession.journeyType === JOURNEY_TYPES.SEASON_PASS
  ) {
    return JOURNEY_TYPES.SEASON_PASS;
  }

  return confirmationSession.journeyType ?? JOURNEY_TYPES.TICKET_FIRST; // The default value used always
};

const getSelectedSeasonPass = (confirmationModel: ConfirmationModel) => {
  const { seasonPassesDetail, ticketTypeModels } = confirmationModel;

  if (!seasonPassesDetail?.seasonPassSessionReceiptDetail) {
    return null;
  }

  const getTicketQuantity = (ticketId: string, ticketQuantity: number) => {
    const seatsWithTicket = seasonPassesDetail.seasonPassSessionReceiptDetail
      .flatMap((s) => s.seatsWithTickets)
      .find((s) => s.ticket.id === ticketId);

    return seatsWithTicket?.ticket.quantity ?? ticketQuantity;
  };

  const ticketTypes: TicketTypes = { ticketTypeModels };

  const selectedSeasonPass: SelectedSeasonPass = {
    sessions: seasonPassesDetail.seasonPassSessionReceiptDetail.map((s) => ({
      seats: s.seatsWithTickets.map((se) => se.seatName),
      session: {
        externalSessionId: s.externalSessionId,
        notBookable: false,
        sessionId: parseInt(s.sessionInfo.sessionId),
        soldOut: false,
        startDateTime: s.sessionInfo.actualSessionDate,
      },
      movie: {
        filmId: parseInt(s.sessionInfo.sessionId),
        imageUrl: s.sessionInfo.bookedFilmData.imageUrl ?? '',
        showTimes: [],
        title: s.sessionInfo.filmName,
      },
    })),
    seasonPassItem: {
      description: '',
      id: '',
      imageUrl: seasonPassesDetail.imageUrl,
      maxRequiredMovies: 0,
      minRequiredMovies: 0,
      ticketTypes: ticketTypes.ticketTypeModels.map((tt) => ({
        displayName: tt.displayName,
        priceInCents: tt.price,
        quantity: getTicketQuantity(tt.id, tt.quantity),
        ticketTypeId: parseInt(tt.id),
        tax: tt.tax,
      })),
      title: seasonPassesDetail.title,
      dealHopk: '',
    },
  };

  return selectedSeasonPass;
};

export const createConfirmation = (
  data: ConfirmationModel,
  customer: GlobalState['customer'],
  journeyType: GlobalState['journeyType']
): ConfirmationSession => {
  const config = data.circuit.config;
  const content = data.circuit.content;

  delete config.cinemas;

  const {
    tax,
    appliedGiftCardPayments,
    bookingConfirmationId,
    codeUsedInBarcode,
    concessionsJourneyParentBookingRef,
    externalOrderId,
    bookingFee,
    cartSummaryModel,
    donation,
    gratuityInCents,
    deliveryWindowInfo,
    selectedSeats,
    selectedConcessions,
    ticketTypeModels,
    totalPrice,
    hasVoucherApplied,
    totalPriceWithDiscount,
    discount,
    registerForCarParkingEncryptedToken,
    ticketCredits,
    orderType,
  } = data;

  const ticketTypes: TicketTypes = { ticketTypeModels };

  const appliedGiftCardsConfirmation: GlobalState['appliedGiftCards'] =
    appliedGiftCardPayments.map((giftCard) => {
      return {
        giftCardBalanceInCents: giftCard.balance,
        giftCardNumber: giftCard.number,
        giftCardPin: giftCard.pin,
      };
    });

  const confirmation: ConfirmationSession = {
    appliedGiftCards: appliedGiftCardsConfirmation,
    cartSummary: cartSummaryModel,
    config,
    content,
    donation,
    gratuityInCents,
    selectedDeliveryWindow: deliveryWindowInfo,
    concessionsJourneyParentBookingRef: concessionsJourneyParentBookingRef,
    bookingFee,
    bookingFeeTax: 0,
    ticketTypes,
    ticketCredits,
    selectedSeats,
    selectedGiftCards: { list: [] },
    selectedConcessions: {
      list: selectedConcessions ?? [],
    },
    confirmationData: {
      bookingConfirmationId,
      codeUsedInBarcode,
      externalOrderId,
      totalPrice: totalPrice - discount,
      productsTax: tax,
      userSessionId: data.userSessionId,
      orderLoyaltyPoints: data.orderLoyaltyPoints,
      orderType: orderType,
    },
    customer: {
      ...customer,
      email: data.emailAddress,
      name: data.customerName,
    },
    grandTotal: totalPrice,
    hasVoucherApplied,
    grandTotalWithDiscount: totalPriceWithDiscount,
    totalDiscount: discount,
    registerForCarParkingEncryptedToken,
    selectedSeasonPass: getSelectedSeasonPass(data),
    journeyType: journeyType,
  };

  confirmation.journeyType = getJourneyTypeFromOrderType(confirmation);

  return confirmation;
};

export const createRefund = (
  data: CreateRefundData,
  customer: GlobalState['customer']
) => {
  const config = data.circuit.config;
  const content = data.circuit.content;
  delete config.cinemas;
  delete content.cinemas;
  const responseRefundData = data.refundData;
  const {
    selectedSeats,
    cartSummaryModel,
    ticketTypeModels,
    dataToken,
    selectedConcessions,
  } = data;
  const {
    tax,
    codeUsedInBarcode,
    externalOrderId,
    bookingFee,
    donation,
    grandTotal,
    loyaltyEmailAddress,
    isUserValidated,
    isRefundPermitted,
    isRefundAllowedForMemberLevelId,
    hasVoucherApplied,
    totalPriceWithDiscount,
    discount,
  } = responseRefundData;
  const ticketTypes = { ticketTypeModels };
  const refundData: RefundData = {
    codeUsedInBarcode,
    externalOrderId,
    isRefundPermitted,
    isRefundAllowedForMemberLevelId,
    isRefundDisallowedByAttribute: false,
  };
  const refund = {
    cartSummary: cartSummaryModel,
    config,
    content,
    donation,
    bookingFee,
    ticketTypes,
    selectedSeats,
    selectedConcessions: {
      list: selectedConcessions ?? [],
    },
    selectedGiftCards: {
      list: [],
    },
    tax,
    customer: {
      ...customer,
      email: responseRefundData.email,
      name: responseRefundData.customerName,
    },
    refundData: refundData,
    grandTotal: grandTotal,
    totalDiscount: discount,
    bookingData: {
      isUserValidated: isUserValidated,
      loyaltyEmailAddress: loyaltyEmailAddress,
    },
    token: dataToken,
    hasVoucherApplied,
    grandTotalWithDiscount: totalPriceWithDiscount,
  };
  return refund;
};

export const getButtonText = (
  journeyType: GlobalState['journeyType'],
  step: GlobalState['step'],
  content: Content,
  config: Config,
  bookingData: BookingData
) => {
  if (!content || !config) return '';
  switch (journeyType) {
    case JOURNEY_TYPES.TICKET_FIRST:
      if (step === 1) {
        return content.continueToSeatsText;
      } else {
        return content.continueToPaymentText;
      }
    case JOURNEY_TYPES.TICKET_FIRST_KIOSK:
      if (step === 1) {
        return content.continueToSeatsText;
      } else {
        return getContinueButtonTextForKioskEnabledJourney(
          step,
          content,
          config,
          bookingData?.isUserValidated
        );
      }
    case JOURNEY_TYPES.SEAT_FIRST:
      if (step === 1) {
        return content.continueToTicketsText;
      } else {
        return content.continueToPaymentText;
      }
    case JOURNEY_TYPES.SEAT_FIRST_KIOSK:
      if (step === 1) {
        return content.continueToTicketsText;
      } else {
        return getContinueButtonTextForKioskEnabledJourney(
          step,
          content,
          config,
          bookingData?.isUserValidated
        );
      }
    case JOURNEY_TYPES.TICKETS_KIOSK:
      return getContinueButtonTextForKioskEnabledJourney(
        step,
        content,
        config,
        bookingData?.isUserValidated
      );
    case JOURNEY_TYPES.CONCESSIONS_ONLY: {
      if (config.enableGiftCardUpsellOnTicketingJourney) {
        return content.continueToGiftCardsText;
      } else {
        return content.continueToPaymentText;
      }
    }
    case JOURNEY_TYPES.GIFTCARDS_ONLY:
    case JOURNEY_TYPES.PHYSICAL_GIFTCARDS_ONLY:
    case JOURNEY_TYPES.GIFTMEMBERSHIP_ONLY:
    case JOURNEY_TYPES.TICKETS_ONLY:
      return content.continueToPaymentText;
    default:
      return '';
  }
};

export const getNumberOfItemsToSelect = (
  journeyType: GlobalState['journeyType'],
  content: Content,
  numberToSelect: number,
  numberSelected: number,
  ofLabel?: string,
  selectedLabel?: string
) => {
  if (!content) return null;
  let subtitle;

  switch (journeyType) {
    case JOURNEY_TYPES.TICKET_FIRST_KIOSK:
    case JOURNEY_TYPES.TICKET_FIRST:
    case JOURNEY_TYPES.SEASON_PASS:
      subtitle = (
        <>
          <span className='all-selected '>{numberSelected}</span>
          {ofLabel && selectedLabel ? (
            <span>{` ${ofLabel} ${numberToSelect} ${selectedLabel}`}</span>
          ) : (
            <span>
              {' '}
              {content.seats.subTitle.replace(
                '##seatsToSelect##',
                `${numberToSelect}`
              )}
            </span>
          )}
        </>
      );
      break;
    case JOURNEY_TYPES.SEAT_FIRST_KIOSK:
    case JOURNEY_TYPES.SEAT_FIRST:
      subtitle = (
        <>
          <span className='all-selected '>{numberSelected} </span>
          <span>
            {' '}
            {content.tickets.seatsFirstSubtitle.replace(
              '##ticketsToSelect##',
              `${numberToSelect}`
            )}
          </span>
        </>
      );
      break;
    default:
      subtitle = null;
  }

  return subtitle;
};

export const getCustomer = (
  bookingData: BookingData,
  captureTelephoneNumber: boolean,
  captureZipCode: boolean,
  isZipCodeRequired: boolean
) => {
  const isZipCodeValid = !captureZipCode || !isZipCodeRequired;

  const customer = {
    name: bookingData?.loyaltyFullName ? bookingData.loyaltyFullName : '',
    nameIsValid: bookingData && !!bookingData.loyaltyFullName,
    email: bookingData?.loyaltyEmailAddress
      ? bookingData.loyaltyEmailAddress
      : '',
    emailIsValid: bookingData && !!bookingData.loyaltyEmailAddress,
    confirmEmail: bookingData?.loyaltyEmailAddress
      ? bookingData.loyaltyEmailAddress
      : '',
    confirmEmailIsValid: bookingData && !!bookingData.loyaltyEmailAddress,
    telephone: bookingData?.loyaltyTelephone
      ? bookingData.loyaltyTelephone
      : '',
    telephoneIsValid: bookingData && !!bookingData.loyaltyTelephone,
    zipCode:
      bookingData?.zipCode && !isZipCodeRequired ? bookingData.zipCode : '',
    zipCodeIsValid: isZipCodeValid,
    isValid: !!(
      bookingData?.loyaltyFullName &&
      bookingData.loyaltyEmailAddress &&
      ((captureTelephoneNumber && bookingData.loyaltyTelephone) ||
        !captureTelephoneNumber) &&
      isZipCodeValid
    ),
  };

  return customer;
};

export const getTotalFabItemsSelected = (
  selectedConcessions: SelectedConcessions
) => {
  return !!selectedConcessions && selectedConcessions.list?.length
    ? selectedConcessions.list.reduce(
        (a: number, b: Concession) => a + (b.quantity || 0),
        0
      )
    : 0;
};

export const getTotalGiftCardsSelected = (
  selectedConcessions: SelectedConcessions
) => {
  return !!selectedConcessions && selectedConcessions.list?.length
    ? selectedConcessions.list
        .filter((x) => !x.isDeliveryItem)
        .reduce((a: number, b: Concession) => a + (b.quantity || 0), 0)
    : 0;
};

export const handleUpdateConcessionsDynamicBasket = async (
  context: string,
  concessions: Concession[],
  dataToken: string,
  journeyType: string,
  selectedDeliveryWindow: DeliveryWindow,
  bookingData: BookingData,
  dispatch: Dispatch<any>,
  content: Content
) => {
  const data: AddConcessionRequestModel = {
    dataToken: dataToken,
    concessions: concessions,
    journeyType: journeyType,
    deliveryWindowInfo: selectedDeliveryWindow,
    isDynamicBasket: false,
  };
  const response = await backend.post(
    `api/Concessions/Add/${bookingData.cinemaId}`,
    data
  );
  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    dispatch(actionCreators.setConcessionsAddedToPos(true));
    dispatch(actionCreators.setToken(response.content.dataToken));
    dispatch(actionCreators.setBookingFee(response.content.bookingFee));
    dispatch(
      actionCreators.setGrandTotalWithDiscount(
        response.content.totalOrderAmount,
        response.content.totalDiscount
      )
    );
  } else {
    dispatch(
      actionCreators.setError(
        getContentForError(response.content.peachCode, content),
        response.content.peachCode
      )
    );
  }
};

export const handleAddRemoveConcessionDynamicBasket = async (
  context: ChangeGiftCardByIdContext,
  concession: Concession,
  dataToken: string,
  journeyType: string,
  selectedDeliveryWindow: DeliveryWindow,
  bookingData: BookingData,
  dispatch: Dispatch<any>,
  content: Content
) => {
  const data: AddConcessionRequestModel = {
    dataToken: dataToken,
    concessions: [concession],
    journeyType: journeyType,
    deliveryWindowInfo: selectedDeliveryWindow,
    isDynamicBasket: true,
  };
  const path = context === 'add' ? 'Add' : 'Remove';
  const response = await backend.post(
    `api/Concessions/${path}/${bookingData.cinemaId}`,
    data
  );
  if (response.ok && response.content.peachCode === PEACH_CODES.noError) {
    dispatch(actionCreators.setConcessionsAddedToPos(true));
    dispatch(actionCreators.setToken(response.content.dataToken));
    dispatch(actionCreators.setBookingFee(response.content.bookingFee));
    dispatch(
      actionCreators.setGrandTotalWithDiscount(
        response.content.totalOrderAmount,
        response.content.totalDiscount
      )
    );
  } else {
    dispatch(
      actionCreators.setError(
        getContentForError(response.content.peachCode, content),
        response.content.peachCode
      )
    );
  }
};

export const handleConcessionQuantityChange = (
  context: QuantitySelectorContext | ChangeGiftCardByIdContext,
  concessionToChange: Concession | undefined,
  concessionIndex: number,
  dispatch: Dispatch<any>,
  isFaB: boolean,
  isQuantityButtonUpdate?: boolean
) => {
  if (concessionToChange === undefined) {
    return null;
  }
  const changeVariablePriceQuantity = () => {
    const newConcession = JSON.parse(JSON.stringify(concessionToChange));
    if (context === 'add') {
      newConcession.quantity += 1;
      dispatch(
        actionCreators.changeConcessionByIndex(
          newConcession,
          concessionIndex,
          isFaB
        )
      );
    } else if (newConcession.quantity > 0) {
      newConcession.quantity -= 1;

      dispatch(
        actionCreators.changeConcessionByIndex(
          newConcession,
          concessionIndex,
          isFaB
        )
      );
    }
  };

  if (concessionToChange.isVariablePriceItem) {
    changeVariablePriceQuantity();
  } else if (context === 'add') {
    dispatch(
      actionCreators.addConcessionWithMods(
        concessionToChange,
        isFaB,
        isQuantityButtonUpdate
      )
    );
  } else {
    dispatch(
      actionCreators.removeConcessionWithMods(concessionToChange, isFaB)
    );
  }
};

export const displaySelectedSeatsNames = (seatsToDisplay: string[]) => {
  return (
    <>
      {seatsToDisplay.map((seatName: string, i: number) => (
        <span key={`${seatName}_${i}`} className='d-inline-block'>
          {seatName}
          {seatsToDisplay.length !== i + 1 ? (
            <span className='dot-separator'>·</span>
          ) : (
            ''
          )}
        </span>
      ))}
    </>
  );
};

const renderDotSeparator = (
  index: number,
  experienceAttributesLength: number
) => {
  return (
    index !== experienceAttributesLength - 1 && (
      <span className='align-middle dot-separator'>·</span>
    )
  );
};

export const renderShowtimeAttributeList = (
  experienceAttributes: ExperienceAttribute[],
  showtimeAttributeFormat: string | undefined,
  isShowtimeHero?: boolean
) => {
  return (
    <>
      {experienceAttributes.map((experienceAttribute, index) => {
        if (
          showtimeAttributeFormat !== 'text' &&
          experienceAttribute.svgLogoContent
        ) {
          return (
            <React.Fragment key={index}>
              <span
                className={classNames(
                  isShowtimeHero
                    ? 'showtime-hero-attribute'
                    : 'showtime-picker-attribute',
                  showtimeAttributeFormat === 'large_icons' && 'large-icons',
                  showtimeAttributeFormat === 'wide_icons' && 'wide-icons'
                )}
                dangerouslySetInnerHTML={{
                  __html: experienceAttribute.svgLogoContent,
                }}
              />
              {showtimeAttributeFormat !== 'wide_icons' &&
                renderDotSeparator(index, experienceAttributes.length)}
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={index}>
              <span className='align-middle'>{experienceAttribute.name}</span>
              {renderDotSeparator(index, experienceAttributes.length)}
            </React.Fragment>
          );
        }
      })}
    </>
  );
};

export const getTotalNumberActualTickets = (
  ticketTypeModels: TicketTypes['ticketTypeModels']
) => {
  return ticketTypeModels.reduce((a, b) => a + (b.quantity || 0), 0);
};

export const getTotalNumberOfSeatsWithinSelectedTickets = (
  ticketTypeModels: TicketTypes['ticketTypeModels'] | undefined
) => {
  return (
    ticketTypeModels?.reduce((a, b) => a + (b.quantity * b.nbSeats || 0), 0) ??
    0
  );
};

type TicketLimitsConfig = {
  ticketTypes: TicketTypes | null;
  ticketsConfig: ConfigTickets;
  t: TicketTypeModel;
};

export const exceedsMaxTickets = ({
  ticketTypes,
  ticketsConfig,
  t,
}: TicketLimitsConfig) => {
  return (
    ticketsConfig.maxTicketsPerOrder &&
    getTotalNumberOfSeatsWithinSelectedTickets(ticketTypes?.ticketTypeModels) +
      t.nbSeats >
      ticketsConfig.maxTicketsPerOrder
  );
};

export const exceedsMaxMemberTickets = ({
  ticketTypes,
  ticketsConfig,
  t,
}: TicketLimitsConfig) => {
  return exceedsTicketsForType(
    ticketTypes,
    ticketsConfig.maxMemberTicketsPerOrder,
    t,
    true
  );
};

export const exceedsMaxGuestTickets = ({
  ticketTypes,
  ticketsConfig,
  t,
}: TicketLimitsConfig) => {
  return exceedsTicketsForType(
    ticketTypes,
    ticketsConfig.maxGuestTicketsPerOrder,
    t,
    false
  );
};

const exceedsTicketsForType = (
  ticketTypes: TicketTypes | null,
  maxTickets: number | undefined,
  t: TicketTypeModel,
  isMember: boolean
) => {
  const currentTotal =
    ticketTypes?.ticketTypeModels?.reduce(
      (a, b) =>
        a + (b.isMemberTicket === isMember ? b.quantity * b.nbSeats || 0 : 0),
      0
    ) ?? 0;
  return (
    t.isMemberTicket === isMember &&
    maxTickets &&
    currentTotal + t.nbSeats > maxTickets
  );
};

export const calculateBookingFeeAndTax = (
  ticketTypeModels: TicketTypeModel[],
  selectedConcessions: SelectedConcessions,
  bookingfeeStrategy: BookingFeeStrategy
) => {
  let calculatedFees = 0;
  let calculatedTaxes = 0;

  switch (bookingfeeStrategy.bookingFeeType) {
    case BookingFeeType.PERSALE:
      calculatedFees = bookingfeeStrategy.bookingFeeValue;
      calculatedTaxes = bookingfeeStrategy.bookingFeeTaxValue;
      break;
    case BookingFeeType.PERITEM: {
      const totalNumberSeats =
        getTotalNumberOfSeatsWithinSelectedTickets(ticketTypeModels);
      calculatedFees = totalNumberSeats * bookingfeeStrategy.bookingFeeValue;
      calculatedTaxes =
        totalNumberSeats * bookingfeeStrategy.bookingFeeTaxValue;
      break;
    }
    case BookingFeeType.PCTPERITEM:
      ticketTypeModels.forEach((ticketType: TicketTypeModel) => {
        calculatedFees +=
          ticketType.quantity *
          (ticketType.price * (bookingfeeStrategy.bookingFeeValue / 100));
        calculatedTaxes +=
          ticketType.quantity *
          (ticketType.price * (bookingfeeStrategy.bookingFeeTaxValue / 100));
      });
      break;
    case BookingFeeType.PCTPERSALE: {
      const cartCost = getCartCostInCents(
        selectedConcessions.list,
        ticketTypeModels
      );
      calculatedFees = cartCost * (bookingfeeStrategy.bookingFeeValue / 100);
      calculatedTaxes =
        cartCost * (bookingfeeStrategy.bookingFeeTaxValue / 100);
      break;
    }
    default:
      calculatedFees = 0;
      calculatedTaxes = 0;
  }
  const finalBookingFee = bookingfeeStrategy.bookingFeeMax
    ? Math.min(calculatedFees, bookingfeeStrategy.bookingFeeMax)
    : calculatedFees;
  return {
    calculatedBookingFee: finalBookingFee,
    calculatedBookingFeeTax: calculatedTaxes,
  };
};

export const calculateBookingFeeForSeasonPasses = (
  seasonPassTickets: SeasonPassTicketType[],
  seasonPassSessions: SelectedSeasonPassSession[],
  bookingfeeStrategy: BookingFeeStrategy
) => {
  let calculatedFees = 0;
  let calculatedTaxes = 0;
  let totalQuantity = seasonPassTickets.reduce(
    (acc, ticket) => acc + ticket.quantity,
    0
  );

  if (seasonPassSessions.length > 0) {
    totalQuantity *= seasonPassSessions.length;
  }

  if (bookingfeeStrategy?.bookingFeeType === BookingFeeType.PERITEM) {
    calculatedFees = totalQuantity * bookingfeeStrategy.bookingFeeValue;
    calculatedTaxes = totalQuantity * bookingfeeStrategy.bookingFeeTaxValue;
  }

  const finalBookingFee = bookingfeeStrategy?.bookingFeeMax
    ? Math.min(calculatedFees, bookingfeeStrategy?.bookingFeeMax)
    : calculatedFees;
  return {
    calculatedBookingFee: finalBookingFee,
    calculatedBookingFeeTax: calculatedTaxes,
  };
};

export const getRequiresDeliveryOptions = (
  journeyType: string,
  foodAndBeverageConcessionsOnlyJourney: FoodAndBeverageJourneyDeliveryType,
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType,
  deliveryWindows: GlobalState['deliveryWindows'],
  foodAndBeverageDeliveryWindowsDisplayOption: FoodAndBeverageDeliveryWindowsDisplayOptionEnum
) => {
  const shouldShowDeliveryWindows =
    !!deliveryWindows?.length &&
    foodAndBeverageDeliveryWindowsDisplayOption !==
      FoodAndBeverageDeliveryWindowsDisplayOptionEnum.DoNotDisplayDeliveryWindows;

  const concessionOnlyRequiresDeliveryOptions =
    [
      FoodAndBeverageJourneyDeliveryType.CollectFromKioskAndOrderToSeat,
      FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly,
      FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly,
    ].includes(foodAndBeverageConcessionsOnlyJourney) &&
    shouldShowDeliveryWindows;

  const ticketingRequiresDeliveryOptions =
    [
      FoodAndBeverageJourneyDeliveryType.CollectFromKioskAndOrderToSeat,
      FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly,
      FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly,
    ].includes(foodAndBeverageTicketingJourney) && shouldShowDeliveryWindows;

  return journeyTypeConfigs[journeyType].isConcessionsOnlyJourney
    ? concessionOnlyRequiresDeliveryOptions
    : ticketingRequiresDeliveryOptions;
};

export const getOrderToSeatOnly = (
  journeyType: string,
  foodAndBeverageConcessionsOnlyJourney: FoodAndBeverageJourneyDeliveryType,
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType
) => {
  return journeyTypeConfigs[journeyType].isConcessionsOnlyJourney
    ? foodAndBeverageConcessionsOnlyJourney ===
        FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly
    : foodAndBeverageTicketingJourney ===
        FoodAndBeverageJourneyDeliveryType.OrderToSeatOnly;
};

export const getCollectFromKioskOnly = (
  journeyType: string,
  foodAndBeverageConcessionsOnlyJourney: FoodAndBeverageJourneyDeliveryType,
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType
) => {
  return journeyTypeConfigs[journeyType].isConcessionsOnlyJourney
    ? foodAndBeverageConcessionsOnlyJourney ===
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly
    : foodAndBeverageTicketingJourney ===
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskOnly;
};

export const getCollectFromKioskAndOrderToSeat = (
  journeyType: string,
  foodAndBeverageConcessionsOnlyJourney: FoodAndBeverageJourneyDeliveryType,
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType
) => {
  return journeyTypeConfigs[journeyType].isConcessionsOnlyJourney
    ? foodAndBeverageConcessionsOnlyJourney ===
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskAndOrderToSeat
    : foodAndBeverageTicketingJourney ===
        FoodAndBeverageJourneyDeliveryType.CollectFromKioskAndOrderToSeat;
};

export const isBansheeSafeEmail = (email: string) =>
  isEmail(email, { allow_utf8_local_part: false });

export const getAttributeWarnings = (
  sessionAttributes: ExperienceAttribute[],
  attributeWarnings: GlobalState['content']['attributeWarnings']
) => {
  if (!sessionAttributes || !attributeWarnings || !attributeWarnings.length) {
    return [] as AttributeWarning[];
  }
  const matchingWarnings: AttributeWarning[] = attributeWarnings.filter(
    (wa: AttributeWarning) =>
      sessionAttributes.some(
        (sa) => sa.name.toLowerCase() === wa.attributeName.toLowerCase()
      )
  );
  return matchingWarnings;
};

export const getScreenWarning = (
  screenName: string,
  screenWarnings: GlobalState['content']['screenWarnings'],
  cinemaId: number
) => {
  if (!screenName || !screenWarnings.length) {
    return undefined;
  }

  const matchingWarning: ScreenWarning | undefined = screenWarnings.find(
    (sw: ScreenWarning) =>
      sw.screenName.toLowerCase() === screenName.toLowerCase() &&
      (!sw.cinemaIds ||
        sw.cinemaIds
          .split(',')
          .map((item: string) => parseInt(item.trim()))
          .includes(cinemaId))
  );

  return matchingWarning;
};

export const getMovieRatingWarning = (
  filmRating: CartSummary['rated'],
  warnings: GlobalState['content']['movieRatingWarnings']
) => {
  if (!filmRating || !warnings.length) {
    return [] as MovieRatingWarning[];
  }
  const matchingWarnings: MovieRatingWarning[] = warnings.filter(
    (wa: MovieRatingWarning) =>
      wa.movieRatingName.toLowerCase() === filmRating.toLowerCase()
  );
  return matchingWarnings;
};

export const getCaptcha = async (
  isEnabled: boolean,
  action: string,
  source: string,
  device: string,
  executeRecaptcha: (action?: string | undefined) => Promise<string>
) => {
  if (!isEnabled) {
    return null;
  }

  let sourceDevice = source + device;
  if (
    sourceDevice !== 'web' &&
    sourceDevice !== 'appios' &&
    sourceDevice !== 'appandroid'
  ) {
    sourceDevice = 'web';
  }

  const recaptchaToken = await executeRecaptcha(action + '_' + sourceDevice);
  return recaptchaToken;
};

export const getTicketDeals = (ticketId: string, deals: Deal[]) => {
  return deals.filter((d: Deal) =>
    d.dealDetails?.some(
      (dd: DealDetails) =>
        !dd.ticketTypeCodeDeal ||
        dd.ticketTypeCodeDeal.length === 0 ||
        dd.ticketTypeCodeDeal.indexOf(ticketId) > -1
    )
  );
};

export const getTicketGiftDeals = (ticketId: string, deals: Deal[]) => {
  return getTicketDeals(ticketId, deals).filter(
    (d: Deal) => d.isConcessionRelated
  );
};

export const isDealRequirementsValid = (
  deal: Deal,
  ticketTypes: TicketTypeModel[]
) => {
  const ticketTypesWithQuantity = ticketTypes.filter((tt) => tt.quantity > 0);
  if (!ticketTypesWithQuantity.length) {
    return false;
  }

  if (!deal.ticketDealRequirements?.length) {
    return true;
  }

  const dealTicketsRequired = deal.ticketDealRequirements.flatMap(
    ({ ticketsRequired }) => ticketsRequired
  );

  const ticketTypeWithQuantity = ticketTypesWithQuantity.find((tr) =>
    dealTicketsRequired.includes(tr.id)
  );

  if (!ticketTypeWithQuantity) {
    return false;
  }

  ticketTypeWithQuantity.quantity -= 1;

  return true;
};

export const setConcessionQualifiedDeals = (
  ticketTypeId: TicketTypeModel['id'],
  dealsInTicketsStep: DealInTicketsStep[],
  deal: Deal,
  context: QuantitySelectorContext
) => {
  const dealInOrder = dealsInTicketsStep.find((d) => d.title === deal.title);

  if (!dealInOrder) {
    dealsInTicketsStep.push({
      title: deal.title,
      onlyValidForRequirements: false,
      appliedByTicketTypes: [{ id: ticketTypeId, quantity: 1 }],
      ticketsQuantityStillToAdd: 0,
    });

    return;
  }

  const appliedByTicketType = dealInOrder.appliedByTicketTypes.find(
    (tt) => tt.id === ticketTypeId
  );

  if (!appliedByTicketType) {
    dealInOrder.appliedByTicketTypes.push({
      id: ticketTypeId,
      quantity: 1,
    });

    return;
  }

  const newQuantity =
    context === 'add'
      ? appliedByTicketType.quantity + 1
      : appliedByTicketType.quantity - 1;

  if (newQuantity > 0) {
    appliedByTicketType.quantity = newQuantity;
    return;
  }

  dealsInTicketsStep.forEach(
    (d) =>
      (d.appliedByTicketTypes = d.appliedByTicketTypes.filter(
        (tt) => tt.id !== ticketTypeId
      ))
  );

  if (!dealInOrder.appliedByTicketTypes) {
    dealsInTicketsStep = dealsInTicketsStep.filter(
      (rd) => rd.title !== deal.title
    );
  }
};

const getDealRequirementTicketsQuantityStillToAdd = (
  deal: Deal,
  ticketTypesWithQuantity: TicketTypeModel[]
) => {
  const matchedDealRequirements = deal.ticketDealRequirements.filter((dr) =>
    dr.ticketsRequired.some((tr) =>
      ticketTypesWithQuantity.some((tq) => tq.id === tr)
    )
  );

  const dealRequirementsQuantity = matchedDealRequirements.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  return dealRequirementsQuantity;
};

const getDealDetailTicketsQuantityStillToAdd = (
  deal: Deal,
  ticketTypesWithQuantity: TicketTypeModel[]
) => {
  const dealDetailsTotalQuantity = deal.dealDetails.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  if (!ticketTypesWithQuantity.length) {
    return dealDetailsTotalQuantity;
  }

  const matchedDealDetails = deal.dealDetails.filter((dd) =>
    dd.ticketTypeCodeDeal.some((tt) =>
      ticketTypesWithQuantity.some((tq) => tq.id === tt)
    )
  );

  const dealDetailsQuantity = matchedDealDetails.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  return dealDetailsQuantity;
};

const getDealTicketsQuantity = (
  deal: Deal,
  ticketTypesWithQuantity: TicketTypeModel[]
) => {
  const matchedDealDetails = deal.dealDetails.filter((dd) =>
    dd.ticketTypeCodeDeal.some((tt) =>
      ticketTypesWithQuantity.some((tq) => tq.id === tt)
    )
  );

  const matchedTicketTypes = ticketTypesWithQuantity.filter((tt) =>
    matchedDealDetails.some((dd) =>
      dd.ticketTypeCodeDeal.some((dc) => dc === tt.id)
    )
  );

  const ticketTypesQuantity = matchedTicketTypes.reduce(
    (a, b) => a + (b.quantity || 0),
    0
  );

  return ticketTypesQuantity;
};

const removeDealFromDealsInTicketsStep = (
  deal: Deal,
  dealsInTicketsStep: DealInTicketsStep[]
) => {
  const index = dealsInTicketsStep.findIndex((rd) => rd.title === deal.title);
  if (index !== -1) {
    dealsInTicketsStep.splice(index, 1);
  }
};

export const setTicketQualifiedDeals = (
  ticketTypeId: TicketTypeModel['id'],
  ticketTypeModels: TicketTypeModel[],
  dealsInTicketsStep: DealInTicketsStep[],
  deal: Deal,
  context: QuantitySelectorContext
) => {
  const fullyQualifiedDealsInOrder = dealsInTicketsStep.filter(
    (d) => d.title === deal.title && !d.onlyValidForRequirements
  );

  if (
    fullyQualifiedDealsInOrder &&
    fullyQualifiedDealsInOrder.length >= deal.maxPerOrder &&
    context === 'add'
  ) {
    return dealsInTicketsStep;
  }

  const ticketTypes = ticketTypeModels.map((tt) => {
    return {
      ...tt,
    };
  });

  const ticketTypesWithQuantity = ticketTypes.filter((tt) => tt.quantity > 0);

  const dealRequirementsQuantity = getDealRequirementTicketsQuantityStillToAdd(
    deal,
    ticketTypesWithQuantity
  );

  const dealDetailsQuantity = getDealDetailTicketsQuantityStillToAdd(
    deal,
    ticketTypesWithQuantity
  );

  const dealTicketsQuantity = getDealTicketsQuantity(
    deal,
    ticketTypesWithQuantity
  );

  const dealRequirementsValid =
    dealTicketsQuantity > 0 &&
    (!dealRequirementsQuantity ||
      dealTicketsQuantity >= dealRequirementsQuantity);

  const totalDealRequiredTickets =
    dealRequirementsQuantity + dealDetailsQuantity;

  const dealDetailsValid =
    dealTicketsQuantity > 0 &&
    (!dealDetailsQuantity || dealTicketsQuantity >= totalDealRequiredTickets);

  if (!dealRequirementsValid && !dealDetailsValid) {
    removeDealFromDealsInTicketsStep(deal, dealsInTicketsStep);
    return;
  }

  const dealInOrder = dealsInTicketsStep.find((d) => d.title === deal.title);

  const ticketsQuantityStillToAdd = Math.max(
    dealRequirementsQuantity + dealDetailsQuantity - dealTicketsQuantity,
    0
  );

  if (!dealInOrder) {
    dealsInTicketsStep.push({
      title: deal.title,
      onlyValidForRequirements: dealRequirementsValid && !dealDetailsValid,
      appliedByTicketTypes: [{ id: ticketTypeId, quantity: 1 }],
      ticketsQuantityStillToAdd,
    });
    return;
  }

  if (dealRequirementsValid && dealDetailsValid && context !== 'add') {
    return;
  }

  dealInOrder.onlyValidForRequirements =
    dealRequirementsValid && !dealDetailsValid;
  dealInOrder.appliedByTicketTypes = [{ id: ticketTypeId, quantity: 1 }];
  dealInOrder.ticketsQuantityStillToAdd = ticketsQuantityStillToAdd;
};

export const getQualifiedDeals = (
  ticketTypeId: TicketTypeModel['id'],
  ticketTypeModels: TicketTypeModel[],
  dealsInTicketsStesp: DealInTicketsStep[],
  deals: Deal[],
  context: QuantitySelectorContext
) => {
  const ticketDeals = getTicketDeals(ticketTypeId, deals);

  ticketDeals.forEach((deal: Deal) => {
    if (deal.isConcessionRelated) {
      // GWP deal
      setConcessionQualifiedDeals(
        ticketTypeId,
        dealsInTicketsStesp,
        deal,
        context
      );
    } else {
      // BOGO or $?? off
      setTicketQualifiedDeals(
        ticketTypeId,
        ticketTypeModels,
        dealsInTicketsStesp,
        deal,
        context
      );
    }
  });

  return dealsInTicketsStesp;
};

export const getMatchingConcessionDeals = (
  concessions: Concession[],
  deals: Deal[]
) => {
  if (!deals || !concessions) {
    return undefined;
  }

  const concessionIds: string[] = concessions.map(
    (c: Concession) => c.id.split('_')[1]
  );
  const matchingDeals: Deal[] = deals.filter((d: Deal) => {
    const concessionCodes = d.dealDetails?.flatMap(
      ({ concessionCodes }) => concessionCodes
    );

    return concessionCodes?.some((code) => concessionIds.includes(code));
  });

  return matchingDeals;
};

export const getMatchingConcessionDeal = (
  concessionId: string,
  deals: Deal[]
) => {
  if (!deals || !concessionId) {
    return undefined;
  }

  const matchingDeal: Deal | undefined = deals.find((d: Deal) => {
    const concessionCodes = d.dealDetails?.flatMap(
      ({ concessionCodes }) => concessionCodes
    );

    return concessionCodes?.includes(concessionId.split('_')[1]);
  });
  return matchingDeal;
};

export const getMatchingConcessionDealDetails = (
  concessionId: string,
  deals: Deal[]
) => {
  if (!deals || !concessionId) {
    return undefined;
  }

  const matchingDeal: Deal | undefined = getMatchingConcessionDeal(
    concessionId,
    deals
  );
  const dealDetail = matchingDeal?.dealDetails?.find((dd: DealDetails) =>
    dd.concessionCodes?.includes(concessionId.split('_')[1])
  );
  return dealDetail;
};

export const getConfirmationShouldShowBarcode = (
  foodAndBeverageConcessionsOnlyJourneyShowBarcode: string,
  hasDeliveryWindow: boolean
) => {
  switch (foodAndBeverageConcessionsOnlyJourneyShowBarcode) {
    case 'show_on_order_to_seat_and_collect_from_kiosk':
      return true;
    case 'show_on_collect_from_kiosk_only':
      if (!hasDeliveryWindow) {
        return true;
      }
      return false;
    case 'show_on_order_to_seat_only':
      if (hasDeliveryWindow) {
        return true;
      }
      return false;
    case 'hide_on_order_to_seat_and_collect_from_kiosk':
      return false;
    default:
      return false;
  }
};

export const combineConcessions = (
  selectedConcessions: SelectedConcessions,
  selectedGiftCards: SelectedConcessions
) => {
  if (selectedConcessions?.list && selectedGiftCards?.list) {
    return [...selectedConcessions.list, ...selectedGiftCards.list];
  }
  if (selectedConcessions?.list) {
    return selectedConcessions.list;
  } else if (selectedGiftCards?.list) {
    return selectedGiftCards.list;
  } else {
    return [];
  }
};

export const shouldShowFoodAndBeverage = (
  foodAndBeverageTicketingJourney: FoodAndBeverageJourneyDeliveryType,
  isFoodAndBeverageLoyaltyOnly: GlobalState['config']['isFoodAndBeverageLoyaltyOnly'],
  isUserValidated: boolean
) => {
  const satisfiesLoyaltyConstraint =
    !isFoodAndBeverageLoyaltyOnly ||
    (isFoodAndBeverageLoyaltyOnly && isUserValidated);
  const notDisabled =
    foodAndBeverageTicketingJourney !==
    FoodAndBeverageJourneyDeliveryType.Disabled;
  return (
    foodAndBeverageTicketingJourney && notDisabled && satisfiesLoyaltyConstraint
  );
};

export const replaceText = (
  originalStr: string,
  pattern: string,
  replacement: string
): string => {
  const escapedPattern = pattern.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
  const patternRgx = new RegExp(escapedPattern, 'g');
  return originalStr.replace(patternRgx, replacement);
};

export const splitLanguageCulture = (languageCulture: string | undefined) => {
  if (!languageCulture) return { language: 'en', country: 'US' };
  const split: string[] = languageCulture.split('-');
  return { language: split[0], country: split[1] };
};

export const countUnavailableSeats = (seatsModel: SelectSeatsModel): number => {
  let totalUnavailableSeats = 0;
  seatsModel.seatsLayoutModel.rows.forEach((row) => {
    row.seats.forEach((seat) => {
      if (seat.isUnavailable) {
        totalUnavailableSeats++;
      }
    });
  });

  return totalUnavailableSeats;
};

export const getSessionOccupancy = (seatsModel: SelectSeatsModel): string => {
  if (!seatsModel?.seatsLayoutModel) return '';
  const totalNumberOfSeats = seatsModel.seatsLayoutModel?.areas?.reduce(
    (acc: number, a: SeatMapArea) => {
      return acc + a.numberOfSeats;
    },
    0
  );
  if (totalNumberOfSeats === 0) return '';
  const unavailableSeats = countUnavailableSeats(seatsModel);

  return (unavailableSeats / totalNumberOfSeats).toFixed(2);
};

export const validateZipCode = (
  zipCode: string,
  minLength: number
): boolean => {
  const trimmedZipCode = zipCode.trim();
  const validCharactersRegEx = /^[a-zA-Z0-9 ]*$/;

  return (
    isLength(trimmedZipCode, { min: minLength, max: 8 }) &&
    validCharactersRegEx.test(trimmedZipCode)
  );
};

export const mapConcessionGroupingsFromApiResponse = (
  concessionGroups: ConcessionGrouping[]
) => {
  return (
    concessionGroups?.map((cg: ConcessionGrouping) => ({
      ...cg,
      items:
        cg.items?.map((c) => ({
          ...c,
          recipeItems: null,
        })) ?? [],
    })) ?? []
  );
};
